import React, { useCallback, useEffect, useMemo, useState } from "react";
import { faPlus, faSave } from "@fortawesome/free-solid-svg-icons";
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
  IonSkeletonText,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import { useNotificationContext } from "../../context/NotificationProvider";
import useTranslation from "../../context/LanguageProvider";
import { Permission } from "../../models/Permissions";
import ProductDto, { ExtraDto } from "../../models/Product";
import { getMatchingProductTypes } from "../../models/Teeth";
import Can from "../Can";
import Icon from "../Icon";
import ProductUpsertModal from "../product/ProductUpsertModal";
import useDentalNotation from "../../hooks/useDentalNotation";
import Select, { OptionTypeBase } from "react-select";
import CreatableSelect from "react-select/creatable";
import ButtonTextIcon from "../ButtonTextIcon";
import { CaseProductDto, CaseProductHasExtra } from "../../models/Case";
import InfoBox from "../InfoBox";
import ExtrasUpsertModal from "../product/ExtrasUpsertModal";
import { useAuthContext } from "../../context/AuthProvider";
import SelectAndButton from "../SelectAndButton";
import ModalWrapper from "../ModalWrapper";
import useLab from "../../context/LabProvider";

interface Props {
  isOpen: boolean;
  initialData: CaseProductDto;
  onCancel: () => void;
  onSuccess: (v: CaseProductDto) => void;
}

interface SelectOption extends OptionTypeBase {
  label: string;
  value: string;
}
interface GroupedOption {
  label: string;
  options: SelectOption[];
}

const ProductSelectModal: React.FC<Props> = ({
  isOpen,
  initialData,
  onCancel,
  onSuccess
}) => {
  const { user } = useAuthContext();
  const { products: labProducts, extras: labExtras } = useLab();

  const [showAddModal, setShowAddModal] = useState(false);
  const [product, setProduct] = useState<CaseProductDto>(initialData);
  const [newExtraIndex, setNewExtraIndex] = useState<number>();
  const [initialExtrasModalData, setInitialExtrasModalData] =
    useState<ExtraDto>({ id: 0, price: 0, name: "" });
  const { printCaseProductTeeth, toothIdToName } = useDentalNotation();
  const { showSuccessToast, showErrorToast } = useNotificationContext();
  const { t, tProductType } = useTranslation();

  const productExtras = useMemo<ExtraDto[]>(() => {
    if (product.productId <= 0) return [];

    return (
      labProducts
        .find(lp => lp.id === product.productId)
        ?.extras.map(e => labExtras.find(ae => ae.id === e)!) ?? []
    );
  }, [product.productId, labProducts, labExtras]);

  const groupedExtras = useMemo<GroupedOption[]>(() => {
    if (!labExtras) {
      return [];
    }
    return [
      {
        label: t("extras.productExtras"),
        options: productExtras.map(e => ({
          label: e.name,
          value: e.id.toString()
        }))
      },
      {
        label: t("extras.otherExtras"),
        options: labExtras
          .filter(e => !productExtras.find(p => p.id === e.id))
          .map(e => ({
            label: e.name,
            value: e.id.toString()
          }))
      }
    ];
  }, [labExtras, productExtras, t]);

  const teeth = useMemo(
    () => printCaseProductTeeth(initialData),
    [initialData, printCaseProductTeeth]
  );
  const initialProduct = useMemo<ProductDto>(
    () => ({
      id: 0,
      name: "",
      price: 0,
      productTypes: getMatchingProductTypes(product.productTypeId),
      extras: []
    }),
    [product.productTypeId]
  );

  const onSubmit = useCallback(
    () =>
      onSuccess({
        ...product,
        extras: product.extras.filter(e => e.extras.id > 0) // we take ony selected extras
      }),
    [product]
  );

  const onProductAdded = async (id: number) => {
    showSuccessToast(t("products.added"));
    setShowAddModal(false);
    setProduct(product => ({ ...product, productId: id, extras: [] }));
  };

  const replaceExtra = (extra: CaseProductHasExtra, index: number) =>
    setProduct(product => ({
      ...product,
      extras: product.extras.map((e, i) => (i === index ? extra : e))
    }));

  const deleteExtra = (i: number) =>
    setProduct(product => ({
      ...product,
      extras: product.extras.filter((_, index) => index !== i)
    }));

  useEffect(() => {
    setProduct(initialData);
  }, [initialData]);

  return (
    <ModalWrapper modalOpened={isOpen} dismiss={onCancel} modal="productSelect">
      <IonModal isOpen={isOpen} onDidDismiss={onCancel}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={onCancel}>
                <ButtonTextIcon button="cancel" />
              </IonButton>
            </IonButtons>
            <IonTitle>
              {product && tProductType(product.productTypeId)}
            </IonTitle>
            <IonButtons slot="primary">
              <IonButton onClick={onSubmit} disabled={product.productId <= 0}>
                <ButtonTextIcon button="save" />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {labProducts && labProducts.length > 0 && (
            <>
              <IonItem lines="none">
                <IonLabel position="stacked">
                  {t("products.selectedTeeth")}
                </IonLabel>
                {teeth}
              </IonItem>
              <SelectAndButton
                label={t("products.title") + "*"}
                select={
                  <Select
                    placeholder={t("products.selectPlaceholder")}
                    classNamePrefix={"rselect"}
                    value={
                      product.productId > 0
                        ? {
                            value: product.productId,
                            label: product.productId
                              ? labProducts.find(
                                  lp => lp.id === product.productId
                                )?.name
                              : ""
                          }
                        : null
                    }
                    onChange={v => {
                      v &&
                        setProduct({
                          ...product,
                          productId: v.value,
                          extras: []
                        });
                    }}
                    options={labProducts.map(p => ({
                      value: p.id,
                      label: p.name
                    }))}
                    noOptionsMessage={() => t("noRecords")}
                  />
                }
                button={
                  <Can permission={Permission.ProductsCreate}>
                    <IonButton
                      color="secondary"
                      fill="outline"
                      onClick={() => setShowAddModal(true)}
                    >
                      <ButtonTextIcon button="newProduct" />
                    </IonButton>
                  </Can>
                }
              />

              <IonGrid>
                <IonItem lines="none" hidden={!product.extras.length}>
                  <IonLabel position="stacked">{t("extras.title")}</IonLabel>
                </IonItem>
                {product.extras.map((extra, i) => (
                  <IonRow key={i + 3000}>
                    <IonCol size-xs="10" size-md="">
                      {labExtras ? (
                        <CreatableSelect
                          placeholder={t("extras.selectExtraPlaceholder")}
                          classNamePrefix={"rselect"}
                          options={groupedExtras}
                          value={
                            extra.extras.id > 0
                              ? {
                                  value: extra.extras.id.toString(),
                                  label: labExtras.find(
                                    ae => ae.id === extra.extras.id
                                  )?.name
                                }
                              : null
                          }
                          onChange={newValue => {
                            if (newValue !== null && newValue.value) {
                              const newExtra = {
                                ...extra,
                                extras: {
                                  id: parseInt(newValue.value),
                                  name: labExtras.find(
                                    extra =>
                                      extra.id.toString() === newValue.value
                                  )!.name
                                }
                              };
                              replaceExtra(newExtra, i);
                            }
                          }}
                          loadingMessage={() => t("loading")}
                          onCreateOption={inputValue => {
                            if (
                              user?.hasPermission(Permission.ProductsCreate)
                            ) {
                              setInitialExtrasModalData({
                                id: 0,
                                price: 0,
                                name: inputValue
                              });
                              setNewExtraIndex(i);
                            } else {
                              showErrorToast(t("noPermissionError"));
                            }
                          }}
                          allowCreateWhileLoading={false}
                          formatCreateLabel={o =>
                            `${t("permissions.types.create")}: "${o}"`
                          }
                          noOptionsMessage={() => t("noRecords")}
                        />
                      ) : (
                        <IonSkeletonText animated />
                      )}
                    </IonCol>
                    <IonCol size="auto" hidden={product.toothIds.length <= 1}>
                      {product.toothIds.map(t => (
                        <span
                          className={
                            extra.toothIds.includes(t)
                              ? "toothSelected"
                              : "toothNotSelected pointer"
                          }
                          onClick={() => {
                            const newExtra = {
                              ...extra,
                              toothIds: extra.toothIds.includes(t)
                                ? extra.toothIds.filter(tooth => tooth !== t)
                                : [...extra.toothIds, t]
                            };
                            replaceExtra(newExtra, i);
                          }}
                          key={t}
                        >
                          {toothIdToName(t)}
                        </span>
                      ))}
                    </IonCol>
                    <Can permission={Permission.ProductsDelete}>
                      <IonCol>
                        <IonButton
                          size="small"
                          color="danger"
                          onClick={() => deleteExtra(i)}
                          className="delete-extra-btnw ion-float-right"
                        >
                          <ButtonTextIcon button="delete" />
                        </IonButton>
                      </IonCol>
                    </Can>
                  </IonRow>
                ))}
                <IonRow hidden={!product.productId}>
                  <IonButton
                    fill="outline"
                    className="ion-margin-top"
                    onClick={() => {
                      setProduct({
                        ...product,
                        extras: [
                          ...product.extras,
                          {
                            toothIds: product.toothIds,
                            extras: { id: 0, name: "" }
                          }
                        ]
                      });
                    }}
                  >
                    <Icon icon={faPlus} /> {t("extras.add")}
                  </IonButton>
                </IonRow>
              </IonGrid>
              <InfoBox
                hidden={
                  !product.id ||
                  !productExtras.length ||
                  product.extras.length > 0
                }
                text={t("extras.noProductExtras")}
              />

              <IonItem lines="none">
                <IonLabel position="stacked">{t("cases.color")}</IonLabel>
                <IonInput
                  autocomplete="new-password"
                  placeholder={t("cases.colorPlaceholder")}
                  clearInput
                  value={product.shade}
                  onIonChange={e =>
                    setProduct(p => ({ ...p, shade: e.detail.value! }))
                  }
                />
              </IonItem>
              <IonButton
                class="ion-margin-top"
                color="secondary"
                expand="block"
                type="submit"
                disabled={product.productId <= 0}
                onClick={onSubmit}
              >
                <Icon icon={faSave} />
                {t("save")}
              </IonButton>
            </>
          )}

          {labProducts && !labProducts.length && (
            <>
              <Can permission={Permission.ProductsCreate}>
                <InfoBox text={t("products.noProductsPleaseAdd")} />
                <IonButton
                  color="secondary"
                  fill="outline"
                  expand="block"
                  onClick={() => setShowAddModal(true)}
                >
                  <ButtonTextIcon button="newProduct" />
                </IonButton>
              </Can>
            </>
          )}

          <ProductUpsertModal
            showModal={showAddModal}
            initialData={initialProduct}
            onSuccess={onProductAdded}
            onCancel={() => setShowAddModal(false)}
          />

          <ExtrasUpsertModal
            showModal={newExtraIndex !== undefined}
            initialData={initialExtrasModalData}
            onCancel={() => {
              setInitialExtrasModalData({ id: 0, price: 0, name: "" });
              setNewExtraIndex(undefined);
            }}
            onSuccess={async extra => {
              try {
                setProduct(product => ({
                  ...product,
                  extras: product.extras.map((e, i) =>
                    i === newExtraIndex ? { ...e, extras: extra } : e
                  )
                }));
              } finally {
                setInitialExtrasModalData({ id: 0, price: 0, name: "" });
                setNewExtraIndex(undefined);
              }
            }}
          />
        </IonContent>
      </IonModal>
    </ModalWrapper>
  );
};

export default ProductSelectModal;
