import React from "react";
import Icon from "./Icon";
import { IconType } from "react-icons/lib";
import { IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";

export function isFaIcon(object: any): object is IconDefinition {
  return "iconName" in object;
}
export function isReactIcon(object: any): object is IconType {
  return "title" in object;
}
export function isCustomIcon(object: any): object is React.ComponentType {
  return "defaultProps" in object;
}

export type IconKind = IconDefinition | IconType | React.ComponentType;

interface Props {
  icon: IconKind;
  slot?: string;
  size?: SizeProp;
  // All other props
  [x: string]: any;
}

const VersatileIcon: React.FC<Props> = ({
  icon: IconProp,
  slot = "start",
  size = "lg",
  ...rest
}) => (
  <>
    {IconProp && isFaIcon(IconProp) && (
      <Icon {...rest} slot={slot} size={size} icon={IconProp} />
    )}
    {IconProp && !isFaIcon(IconProp) && <IconProp {...rest}></IconProp>}
  </>
);

export default VersatileIcon;
