import { faPlus, faSave, faSpinner } from "@fortawesome/free-solid-svg-icons";
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import React, { useCallback, useEffect, useState } from "react";
import { useNotificationContext } from "../../context/NotificationProvider";
import useApi from "../../data/Api";
import { parseNumber } from "../../data/numberHelpers";
import useTranslation from "../../context/LanguageProvider";
import { ProductViewDto } from "../../models/Product";
import ButtonTextIcon from "../ButtonTextIcon";
import Icon from "../Icon";
import { Permission } from "../../models/Permissions";
import ModalWrapper from "../ModalWrapper";
import useLab from "../../context/LabProvider";
import MaterialUpsertModal from "../material/MaterialUpsertModal";
import Can from "../Can";

interface Props {
  onSuccess: (id: number) => void;
  onCancel: () => void;
  showModal: boolean;
  initialData: ProductViewDto;
}

const initialMaterial = {
  id: 0,
  name: "",
  code: "",
  unit: "#"
};

type ChangeEventType = CustomEvent<{ value: string | null | undefined }>;

const ProductMaterialsUpsertModal: React.FC<Props> = ({
  onSuccess,
  onCancel,
  initialData,
  showModal
}) => {
  const { apiPost } = useApi();
  const { handleError } = useNotificationContext();
  const { t, tPlaceholder } = useTranslation();
  const { materials } = useLab();

  const [showExtrasUpsertModal, setShowMaterialUpsertModal] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [product, setProduct] = useState<ProductViewDto>(initialData);

  useEffect(() => {
    setProduct(initialData);
  }, [initialData]);

  const upsert = useCallback(() => {
    setSubmitting(true);
    apiPost<number>("product/saveMaterials", product)
      .then(onSuccess)
      .catch(handleError)
      .finally(() => setSubmitting(false));
  }, [apiPost, handleError, product]);

  const addMaterial = useCallback((id: number) => {
    id &&
      setProduct(p => ({
        ...p,
        materials: [...p.materials, { materialId: id, quantity: 1 }]
      }));
  }, []);

  const onQuantityChange = useCallback(
    (id: number) => (event: ChangeEventType) => {
      setProduct(product => ({
        ...product,
        materials: product.materials.map(m =>
          m.materialId === id
            ? { ...m, quantity: parseNumber(event.detail.value) ?? 0 }
            : m
        )
      }));
    },
    []
  );

  return (
    <ModalWrapper
      modalOpened={showModal}
      dismiss={onCancel}
      modal="materialUpsert"
    >
      <IonModal isOpen={showModal} onDidDismiss={onCancel}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={onCancel}>
                <ButtonTextIcon button="cancel" />
              </IonButton>
            </IonButtons>
            <IonTitle>{initialData.name}</IonTitle>
            <IonButtons slot="primary">
              <IonButton onClick={upsert} disabled={isSubmitting}>
                <ButtonTextIcon button="save" loading={isSubmitting} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonGrid>
            {product.materials.map(material => {
              const { name, unit } =
                materials.find(m => m.id === material.materialId) ??
                initialMaterial;
              return (
                <IonRow key={material.materialId}>
                  <IonCol size="6">
                    <IonItem className="ion-no-padding" lines="none">
                      <IonLabel position="stacked">{t("name")}</IonLabel>
                      <IonInput
                        autocomplete="new-password"
                        readonly
                        type="text"
                        value={name}
                      />
                    </IonItem>
                  </IonCol>
                  <IonCol size="2">
                    <IonItem className="ion-no-padding" lines="none">
                      <IonLabel position="stacked">
                        {t("materials.unit")}
                      </IonLabel>
                      <IonInput
                        autocomplete="new-password"
                        readonly
                        type="text"
                        value={unit}
                      />
                    </IonItem>
                  </IonCol>
                  <IonCol size="4">
                    <IonItem lines="none">
                      <IonLabel position="stacked">{t("quantity")}</IonLabel>
                      <IonInput
                        type="number"
                        inputMode="numeric"
                        step="1"
                        min="1"
                        value={material.quantity}
                        placeholder={tPlaceholder("quantity")}
                        onIonChange={onQuantityChange(material.materialId)}
                      />
                    </IonItem>
                  </IonCol>
                </IonRow>
              );
            })}
            <IonRow className="ion-margin-top">
              <IonCol size="6" className="ion-padding-top">
                <IonSelect
                  interface="action-sheet"
                  placeholder={t("materials.selectMaterial")}
                  value={null}
                  onIonChange={e => addMaterial(e.detail.value)}
                >
                  {materials
                    .filter(
                      material =>
                        !product.materials.some(
                          m => m.materialId === material.id
                        )
                    )
                    .map(material => (
                      <IonSelectOption key={material.id} value={material.id}>
                        {material.name}
                      </IonSelectOption>
                    ))}
                </IonSelect>
              </IonCol>
              <Can permission={Permission.MaterialsCreate}>
                <IonCol size="6">
                  <IonButton
                    class="ion-margin-top"
                    color="secondary"
                    expand="block"
                    type="submit"
                    disabled={isSubmitting}
                    onClick={() => setShowMaterialUpsertModal(true)}
                  >
                    <Icon icon={faPlus} />
                    {t("materials.new")}
                  </IonButton>
                </IonCol>
              </Can>
            </IonRow>
          </IonGrid>
          <IonButton
            class="ion-margin-top"
            color="secondary"
            expand="block"
            type="submit"
            disabled={isSubmitting}
            onClick={upsert}
          >
            {isSubmitting ? (
              <Icon spin icon={faSpinner} />
            ) : (
              <Icon icon={faSave} />
            )}
            {t("save")}
          </IonButton>
          <Can permission={Permission.MaterialsCreate}>
            <MaterialUpsertModal
              showModal={showExtrasUpsertModal}
              initialData={initialMaterial}
              onCancel={() => setShowMaterialUpsertModal(false)}
              onSuccess={id => {
                setShowMaterialUpsertModal(false);
                addMaterial(id);
              }}
            />
          </Can>
        </IonContent>
      </IonModal>
    </ModalWrapper>
  );
};

export default ProductMaterialsUpsertModal;
