import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonSelectOption,
  IonBackButton,
  IonNote
} from "@ionic/react";
import React from "react";
import Form from "../form/Form";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";
import { useHistory } from "react-router";
import withPermission from "../../data/withPermission";
import { Permission } from "../../models/Permissions";
import Role from "../../models/Role";
import EmailFormItem from "../form/EmailFormItem";
import useTranslation from "../../context/LanguageProvider";
import MiddleBox from "../MiddleBox";
import InfoBox from "../InfoBox";
import FormInput from "../form/FormIonInput";
import FormIonSelect from "../form/FormIonSelect";

interface InviteEmployeeDto {
  name: string;
  email: string;
  roleId: string;
}

const InviteEmployee: React.FC = () => {
  const { apiPut } = useApi();
  const { handleError, showSuccessToast } = useNotificationContext();
  const history = useHistory();
  const { t, tInterpolated, tRole, tRequired, tPlaceholder } = useTranslation();

  const invite = (data: InviteEmployeeDto) =>
    apiPut("invitation/create", { ...data, roleId: parseInt(data.roleId) })
      .then(res => {
        showSuccessToast(
          tInterpolated("inviteEmployee.invitationCreated", {
            email: data.email
          })
        );
        history.replace("view-invitation/" + res);
      })
      .catch(handleError);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/employees" />
          </IonButtons>
          <IonTitle>{t("inviteEmployee.title")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <MiddleBox className="box">
          <Form
            onSubmit={invite}
            submitButtonText={t("inviteEmployee.createCode")}
            initialData={{
              name: "",
              email: "",
              roleId: Role.Manager.toString()
            }}
          >
            <div className="ion-margin">
              <IonNote>{t("inviteEmployee.description")}</IonNote>
            </div>

            <FormInput
              name="name"
              label={t("name")}
              rules={{
                required: tRequired("name")
              }}
              placeholder={tPlaceholder("name")}
              clearInput
            />
            <EmailFormItem required />

            <FormIonSelect
              name="roleId"
              label={t("role")}
              rules={{
                required: tRequired("role"),
                valueAsNumber: true
              }}
              placeholder={t("selectOne")}
              interface="action-sheet"
              hidden
            >
              <IonSelectOption value={Role.Technician}>
                {tRole(Role.Technician)}
              </IonSelectOption>
              <IonSelectOption value={Role.Manager}>
                {tRole(Role.Manager)}
              </IonSelectOption>
              <IonSelectOption value={Role.Admin}>
                {tRole(Role.Admin)}
              </IonSelectOption>
            </FormIonSelect>

            {/* <InfoBox text={t("hints.role" + Role.Technician)}></InfoBox>
            <InfoBox text={t("hints.role" + Role.Manager)}></InfoBox>
            <InfoBox text={t("hints.role" + Role.Admin)}></InfoBox>
            <InfoBox text={t("hints.rolesNote")}></InfoBox> */}
          </Form>
        </MiddleBox>
      </IonContent>
    </IonPage>
  );
};

export default withPermission(InviteEmployee, Permission.InvitationsCreate);
