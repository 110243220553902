import {
  IonItem,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonSkeletonText,
  IonInput,
  useIonViewWillEnter,
  IonButton,
  IonToolbar,
  IonSegment,
  IonSegmentButton
} from "@ionic/react";
import DataTable, { Media, TableColumn } from "react-data-table-component";

import React, { useCallback, useEffect, useState } from "react";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";
import { useHistory } from "react-router-dom";
import {
  InvoiceListDto,
  InvoiceStatus,
  InvoiceSummaryDto
} from "../../models/Invoice";
import { SortDirection } from "../../models/PagedData";
import {
  faCircle,
  faDownload,
  faSpinner
} from "@fortawesome/free-solid-svg-icons";
import Icon from "../Icon";
import useTranslation from "../../context/LanguageProvider";
import NoResults from "../NoResults";
import { InvoicesUrlProps } from "../../models/UrlProps";
import useDate from "../../hooks/useDate";
import ValueLabel from "../ValueLabel";
import useCurrency from "../../hooks/useCurrency";
import SelectDoctor from "../doctor/SelectDoctor";
import { Select } from "antd";
import ItemLabel from "../ItemLabel";
import PopupDateRangePicker from "../PopupDateRangePicker";
import InvoicesStats from "../stats/InvoicesStats";
import { RefresherEventDetail } from "@ionic/core/components";
import Refresher from "../Refresher";
import { useAuthContext } from "../../context/AuthProvider";

const { Option } = Select;

interface Props {
  mode: "allInvoices" | "doctorInvoices";
  initialUrlProps: InvoicesUrlProps;
  showFilterProp: boolean;
  searchProp: string;
  exposeSaveAsPdf?: (saveAsPdf: () => void) => void;
  setSavingPdf?: (v: boolean) => void;
}

const InvoicesTable: React.FC<Props> = ({
  mode,
  initialUrlProps,
  showFilterProp,
  searchProp,
  exposeSaveAsPdf,
  setSavingPdf
}) => {
  const [showFilter, setShowFilter] = useState(showFilterProp);
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [segment, setSegment] = useState<"table" | "summary">("table");
  const history = useHistory();

  const { user } = useAuthContext();
  const { apiPost, apiBlobDownload } = useApi();
  const { handleError } = useNotificationContext();
  const { formatWithCurrencySign } = useCurrency();
  const [invoices, setInvoices] = useState<InvoiceSummaryDto>();
  const { t, tInvoiceStatus } = useTranslation();
  const { toDateString } = useDate();

  const [urlProps, setUrlProps] = useState<InvoicesUrlProps>({
    ...initialUrlProps,
    search: searchProp
  });

  useIonViewWillEnter(() => {
    getData();
  }, [urlProps]);

  const getData = useCallback(
    (refreshEvent?: CustomEvent<RefresherEventDetail>) => {
      setLoading(true);
      apiPost<InvoiceSummaryDto>(`invoice/getFiltered`, urlProps)
        .then(setInvoices)
        .catch(handleError)
        .finally(() => {
          if (refreshEvent) refreshEvent.detail.complete();
          setLoading(false);
        });
    },
    [apiPost, handleError, urlProps]
  );

  useEffect(() => {
    setShowFilter(showFilterProp);
  }, [showFilterProp]);

  useEffect(() => {
    if (searchProp !== urlProps.search)
      setUrlProps(urlProps => ({ ...urlProps, search: searchProp }));
  }, [searchProp, urlProps]);

  useEffect(() => {
    getData();
  }, [getData]);

  const saveAsPdf = useCallback(() => {
    setDownloading(true);
    apiBlobDownload("POST", `invoice/SaveListAsPdf`, urlProps)
      .catch(handleError)
      .finally(() => setDownloading(false));
  }, [apiBlobDownload, handleError, urlProps]);

  useEffect(() => {
    if (exposeSaveAsPdf) exposeSaveAsPdf(() => saveAsPdf);
  }, [exposeSaveAsPdf, saveAsPdf]);

  useEffect(() => setSavingPdf && setSavingPdf(downloading), [downloading]);

  const handleSort = (
    column: TableColumn<InvoiceListDto>,
    sortDirection: "desc" | "asc"
  ) => {
    setUrlProps(urlProps => ({
      ...urlProps,
      sortDirection:
        sortDirection === "desc" ? SortDirection.Desc : SortDirection.Asc,
      sortBy: column.selector?.toString()
    }));
  };

  const handlePageChange = (page: number) =>
    setUrlProps(urlProps => ({ ...urlProps, page: page }));

  const handleDoctorChange = (id: number | undefined) =>
    setUrlProps(urlProps => ({
      ...urlProps,
      doctorId: id
    }));

  const columns: TableColumn<InvoiceListDto>[] = [
    {
      name: t("invoices.number"),
      selector: () => "number",
      sortable: true,
      grow: 2,
      cell: row => (
        <IonItem
          lines="none"
          class="ion-no-padding"
          routerLink={"/invoice/" + row.id}
        >
          <Icon
            slot="start"
            title={tInvoiceStatus(row.status)}
            size="1x"
            icon={faCircle}
            className={"invoiceIcon" + row.status}
          />
          <IonLabel className="content-font">
            {row.number ? row.number : ""}
            <p hidden>
              {t("status")} {tInvoiceStatus(row.status)}
            </p>
            <p className="ion-hide-sm-up">
              <b hidden={user?.doctorId !== undefined}>{row.doctor.name}</b>
              {}
              {row.case.patient && <span>, {row.case.patient}</span>}
              {row.case.pan && <b>, {row.case.pan}</b>}
            </p>
            <p className="ion-hide-sm-up">
              {t("paid")}: {formatWithCurrencySign(row.total)},{" "}
              {t("balanceDue")}:{" "}
              <span className={"invoiceIcon" + row.status}>
                {formatWithCurrencySign(row.total - row.totalPaid)}
              </span>
            </p>
            <p className="ion-hide-sm-up smaller-font margin-top-6">
              {t("cases.received") + ": " + toDateString(row.case.received)}
            </p>
          </IonLabel>
        </IonItem>
      )
    },
    {
      name: t("doctor"),
      selector: () => "doctor",
      sortable: true,
      grow: 1,
      cell: row => row.doctor.name,
      hide: Media.SM,
      omit: user?.doctorId !== undefined
    },
    {
      name: t("patient"),
      selector: () => "patient",
      sortable: true,
      grow: 1,
      cell: row => row.case.patient,
      hide: Media.SM
    },
    {
      name: t("cases.pan"),
      selector: () => "pan",
      sortable: true,
      grow: 1,
      cell: row => row.case.pan,
      hide: Media.SM
    },
    {
      name: t("cases.received"),
      selector: () => "received",
      sortable: true,
      grow: 1,
      cell: row => toDateString(row.case.received),
      hide: Media.SM
    },
    {
      name: t("total"),
      selector: () => "total",
      sortable: true,
      grow: 1,
      cell: row => formatWithCurrencySign(row.total),
      hide: Media.SM
    },
    {
      name: t("balanceDue"),
      selector: () => "balanceDue",
      sortable: true,
      grow: 1,
      cell: row => (
        <span className={"invoiceIcon" + row.status}>
          {formatWithCurrencySign(row.total - row.totalPaid)}
        </span>
      ),
      hide: Media.SM
    }
  ];

  return (
    <>
      <Refresher onRefresh={e => getData(e)} />
      <IonGrid>
        <IonRow hidden={!showFilter}>
          <IonCol size-xs="6" size-xl="3" hidden={mode === "doctorInvoices"}>
            <SelectDoctor onChange={handleDoctorChange} />
          </IonCol>

          <IonCol size-xs="6" size-xl="3" hidden={mode !== "doctorInvoices"}>
            <ItemLabel label={t("search")}>
              <IonInput
                debounce={200}
                className="ion-no-margin"
                placeholder={t("search")}
                onIonChange={e =>
                  setUrlProps(urlProps => ({
                    ...urlProps,
                    search: e.detail.value!
                  }))
                }
              />
            </ItemLabel>
          </IonCol>
          <IonCol size-xs="6" size-xl="3">
            <ItemLabel label={t("status")}>
              <Select
                size="large"
                // mode="multiple"
                defaultValue={urlProps.status !== null ? urlProps.status : ""}
                onChange={e => {
                  setUrlProps(urlProps => ({
                    ...urlProps,
                    status: e === "" ? null : (e as InvoiceStatus)
                  }));
                }}
              >
                <Option value="" key="0">
                  {t("all")}
                </Option>
                <Option key="1" value={InvoiceStatus.Unpaid}>
                  {tInvoiceStatus(InvoiceStatus.Unpaid)}
                </Option>
                <Option key="1" value={InvoiceStatus.PartlyPaid}>
                  {tInvoiceStatus(InvoiceStatus.PartlyPaid)}
                </Option>
                <Option key="1" value={InvoiceStatus.Paid}>
                  {tInvoiceStatus(InvoiceStatus.Paid)}
                </Option>
              </Select>
            </ItemLabel>
          </IonCol>
          <IonCol>
            <ItemLabel label={t("dates.chooseDate")}>
              <PopupDateRangePicker
                onChange={({ start, end }) =>
                  setUrlProps(urlProps => ({
                    ...urlProps,
                    fromDate: start,
                    toDate: end
                  }))
                }
                start={urlProps.fromDate}
                end={urlProps.toDate}
              />
            </ItemLabel>
          </IonCol>
        </IonRow>

        <IonToolbar>
          <IonSegment value={segment} mode="ios">
            <IonSegmentButton value="table" onClick={() => setSegment("table")}>
              {t("table")}
            </IonSegmentButton>
            <IonSegmentButton
              value="summary"
              onClick={() => setSegment("summary")}
            >
              {t("summary")}
            </IonSegmentButton>
          </IonSegment>
        </IonToolbar>
        {!invoices && loading && (
          <IonSkeletonText animated title={t("loading")} />
        )}

        {invoices && (
          <>
            {segment === "table" && (
              <>
                <DataTable
                  className="box lower-top-padding"
                  noHeader
                  pointerOnHover
                  columns={columns}
                  data={invoices.data.data}
                  progressPending={loading}
                  progressComponent={<IonSkeletonText animated />}
                  pagination
                  paginationServer
                  paginationTotalRows={invoices.data.count}
                  defaultSortFieldId="created"
                  defaultSortAsc={false}
                  onRowClicked={row => history.push("/invoice/" + row.id)}
                  onChangeRowsPerPage={rows =>
                    setUrlProps(urlProps => ({ ...urlProps, pageSize: rows }))
                  }
                  noDataComponent={
                    <NoResults title={t("noRecords")}></NoResults>
                  }
                  sortServer
                  onSort={handleSort}
                  onChangePage={handlePageChange}
                  paginationComponentOptions={{
                    rowsPerPageText: t("dataTable.rowsPerPageText"),
                    rangeSeparatorText: t("dataTable.rangeSeparatorText")
                  }}
                />
              </>
            )}

            {segment === "summary" && invoices.data.data.length === 0 && (
              <NoResults title={t("noData")} />
            )}

            {segment === "summary" && invoices.data.data.length > 0 && (
              <IonRow class="box">
                <IonCol size-xs="12" size-md="4">
                  <ValueLabel title={t("total")}>
                    <b>{formatWithCurrencySign(invoices.total)}</b>
                  </ValueLabel>
                </IonCol>
                <IonCol size-xs="12" size-md="4">
                  <ValueLabel title={t("totalPaid")}>
                    <b>{formatWithCurrencySign(invoices.totalPaid)}</b>
                  </ValueLabel>
                </IonCol>
                <IonCol size-xs="12" size-md="4">
                  <ValueLabel title={t("balanceDue")}>
                    <b
                      className={
                        invoices.balanceDue > 0
                          ? "warningColor"
                          : "successColor"
                      }
                    >
                      {formatWithCurrencySign(invoices.balanceDue)}
                    </b>
                  </ValueLabel>
                </IonCol>
              </IonRow>
            )}

            <InvoicesStats {...urlProps} hidden={segment !== "summary"} />

            {invoices.data.data.length > 0 && !loading && (
              <div className="ion-text-center ion-margin-top">
                <IonButton
                  fill="outline"
                  onClick={saveAsPdf}
                  disabled={downloading}
                >
                  <Icon
                    icon={downloading ? faSpinner : faDownload}
                    spin={downloading}
                  />
                  {t("saveAsPdf")}
                </IonButton>
              </div>
            )}
          </>
        )}
      </IonGrid>
    </>
  );
};

export default InvoicesTable;
