import React from "react";
import CustomIcon, { CustomIconProps } from "./CustomIcon";

const Icon: React.FC<CustomIconProps> = props => (
  <CustomIcon
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    {...props}
  >
    <g>
      <g>
        <path
          d="M481.692,93.619c-13.637-42.485-42.709-73.665-79.762-85.547C333.618-13.834,277.445,14.332,256,27.816
			c-21.448-13.485-77.622-41.65-145.93-19.744C73.006,19.957,43.931,51.149,30.298,93.65
			c-17.927,55.888-6.35,119.199,30.972,169.355c16.226,21.809,27.197,45.851,32.607,71.46c2.403,11.378,4.492,23.896,6.208,37.208
			c5.153,39.977,12.742,72.063,22.558,95.366c11.758,27.915,26.927,42.99,45.085,44.806c1.034,0.103,2.055,0.154,3.07,0.154
			c17.872-0.001,32.809-16.041,44.429-47.744c8.591-23.437,15.363-54.842,19.07-88.432c2.072-18.782,6.919-33.99,14.015-43.98
			c2.541-3.576,6.224-3.962,7.689-3.962c1.465,0,5.148,0.386,7.689,3.963c7.096,9.989,11.943,25.197,14.015,43.98
			c3.707,33.589,10.479,64.995,19.07,88.432c11.62,31.704,26.555,47.745,44.429,47.744c1.014,0,2.038-0.052,3.07-0.154
			c18.158-1.816,33.327-16.891,45.085-44.806c9.815-23.303,17.405-55.389,22.558-95.366c1.716-13.314,3.805-25.831,6.207-37.202
			c5.406-25.589,16.393-49.655,32.656-71.53C488.069,212.786,499.625,149.487,481.692,93.619z M426.765,245.088
			c-18.857,25.365-31.615,53.357-37.92,83.199c-2.567,12.148-4.79,25.459-6.608,39.562
			c-10.489,81.373-30.034,112.784-40.753,114.198c-2.251-0.797-9.951-8.199-18.229-32.688
			c-7.016-20.752-12.628-48.033-15.805-76.818c-2.667-24.173-9.182-43.696-19.364-58.028c-7.365-10.368-19.36-16.558-32.086-16.558
			c-12.726,0-24.721,6.189-32.087,16.559c-10.182,14.333-16.696,33.856-19.364,58.028c-3.177,28.785-8.789,56.066-15.804,76.818
			c-8.278,24.489-15.979,31.891-18.23,32.688c-10.718-1.414-30.264-32.825-40.753-114.198c-1.818-14.1-4.041-27.413-6.609-39.568
			c-6.309-29.866-19.053-57.837-37.877-83.138c-31.567-42.425-41.466-95.641-26.483-142.352
			c10.645-33.187,32.665-57.324,60.414-66.222c29.294-9.395,59.501-8.793,89.781,1.787c23.128,8.082,37.641,19.261,37.749,19.345
			l9.241,7.225l9.248-7.197c0.144-0.112,14.657-11.291,37.785-19.373c30.28-10.581,60.486-11.182,89.781-1.787h0.001
			c27.74,8.895,49.758,33.023,60.406,66.196C468.186,149.459,458.305,202.664,426.765,245.088z"
        />
      </g>
    </g>
  </CustomIcon>
);

export default Icon;
