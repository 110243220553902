import React, { useCallback, useEffect, useState } from "react";
import {
  IonRouterOutlet,
  IonLabel,
  IonMenu,
  IonContent,
  IonList,
  IonMenuToggle,
  IonItem,
  IonListHeader,
  IonSplitPane,
  IonLoading,
  IonImg,
  IonSkeletonText,
  IonBadge
} from "@ionic/react";
import { Route, useLocation } from "react-router";
import { useAuthContext } from "../../context/AuthProvider";

/* Icons */

/*Components*/
import AuthRoute from "./AuthRoute";
import NonAuthRoute from "./NonAuthRoute";

/*Pages*/
import MainTabs from "../MainTabs";
import NotFound from "../NotFound";
import AcceptInvitation from "../employee/AcceptInvitation";
import Login from "../auth/Login";
import Register from "../auth/Register";
import ForgotPassword from "../auth/ForgotPassword";
import VerifyEmail from "../auth/VerifyEmail";
import useTranslation, { Languages } from "../../context/LanguageProvider";
import InitialPath from "./InitialPath";
import { firebaseMessaging } from "../../data/firebase";
import { onMessage } from "firebase/messaging";
import { useNotificationContext } from "../../context/NotificationProvider";
import usePushNotifications from "../../data/pushNotifications";
import useLab from "../../context/LabProvider";
import { ActiveCasesSummary } from "../../models/Case";
import { timeInPast } from "../../data/dateHelpers";
import useUrlSearchParams from "../../hooks/useUrlSearchParams";
import {
  Page,
  doctorHiddenPages,
  myLabPages,
  noLabPages,
  accountPages,
  allPages,
  PageIcon
} from "./navigationPages";
import { MdLogout } from "react-icons/md";

const Navigation: React.FC = () => {
  const location = useLocation();
  const { authenticated, loadingAuthState, user, logout } = useAuthContext();
  const { lab, loadingLab, logoImg, dashboardData, myTasksSummary } = useLab();
  const { t } = useTranslation();
  const [tabPages, setTabPages] = useState<Page[]>([]);
  const { showToast } = useNotificationContext();
  const { notificationsGranted } = usePushNotifications();
  const [activeCasesSummary, setActiveCasesSummary] =
    useState<ActiveCasesSummary>({ count: 0, missedDeadline: false });

  const urlLanguage = useUrlSearchParams("language");
  const { language: currentLanguage, changeLanguage } = useTranslation();
  useEffect(() => {
    if (urlLanguage && urlLanguage !== currentLanguage) {
      // language from the query is different from the current language
      const l = Languages.find(l => l.value === urlLanguage);
      // see if the one from the query is one of our own
      l && changeLanguage(l.value);
    }
  }, [urlLanguage, currentLanguage, changeLanguage]);

  const hasPagePermission = useCallback(
    (page: Page) => {
      if (!user) return false;
      if (!page.permission) return true; // no permission needed

      return user.hasPermission(page.permission);
    },
    [user]
  );

  const isPageAllowedForDoctor = useCallback(
    (page: Page) => {
      if (!user) return false;
      if (!user.doctorId) return true; // simple employee

      return !doctorHiddenPages.includes(page.url);
    },
    [user]
  );

  useEffect(() => {
    if (!user) setTabPages([]);
    else
      setTabPages(
        myLabPages
          .filter(page => page.url.startsWith("/tabs"))
          .filter(hasPagePermission)
          .filter(isPageAllowedForDoctor)
      );
  }, [hasPagePermission, isPageAllowedForDoctor, user]);

  useEffect(() => {
    // Handle incoming messages. Called when:
    // - a message is received while the app has focus
    // - the user clicks on an app notification created by a service worker
    //   `messaging.onBackgroundMessage` handler.
    if (!notificationsGranted) return;

    return onMessage(firebaseMessaging, payload => {
      console.log(payload);
      payload.notification?.body &&
        showToast({
          message: payload.notification.body,
          duration: 3000,
          color: "success"
        });
    });
  }, [notificationsGranted, showToast]);

  useEffect(() => {
    setActiveCasesSummary({
      count: dashboardData?.length ?? 0,
      missedDeadline:
        dashboardData &&
        dashboardData.find(
          d => d.appointmentDate && timeInPast(new Date(d.appointmentDate))
        )
          ? true
          : false
    });
  }, [dashboardData]);

  if (loadingAuthState)
    return <IonLoading isOpen message={t("loggingInMessage")} />;
  if (user && !user.emailVerified) {
    return <VerifyEmail />;
  }

  const renderListItems = (pages: Page[]) => {
    return pages
      .filter(hasPagePermission)
      .filter(isPageAllowedForDoctor)
      .map((page, index) => (
        <IonMenuToggle key={index} autoHide={false}>
          <IonItem
            className={location.pathname === page.url ? "selected" : ""}
            routerLink={page.url}
            routerDirection="root"
            lines="none"
            detail={false}
          >
            <PageIcon {...page} />

            <IonLabel>
              {t(page.title)}{" "}
              {page.url === "/tabs/myTasks" && myTasksSummary && (
                <IonBadge
                  className="to-do-badge"
                  color={
                    myTasksSummary.missedDeadlineCount ? "danger" : "warning"
                  }
                >
                  {myTasksSummary.scheduledCount + myTasksSummary.startedCount}
                </IonBadge>
              )}
              {page.url === "/tabs/dashboard" &&
                activeCasesSummary.count > 0 && (
                  <IonBadge
                    className="to-do-badge"
                    color={
                      activeCasesSummary.missedDeadline ? "danger" : "warning"
                    }
                  >
                    {activeCasesSummary.count}
                  </IonBadge>
                )}
            </IonLabel>
          </IonItem>
          <hr className="menu-line" />
        </IonMenuToggle>
      ));
  };

  return (
    <IonSplitPane contentId="main">
      {authenticated && (
        <IonMenu contentId="main" swipeGesture={true} type="overlay">
          <IonContent className="ion-no-padding">
            <IonImg className="logo-menu" src={"assets/img/logo.png"} alt="" />
            {lab && (
              <>
                {logoImg && (
                  <IonImg
                    className="lab-logo"
                    src={logoImg}
                    alt="assets/img/logo.png"
                  />
                )}
                <div className="lab-name">{lab.name}</div>
                <IonList>{renderListItems(myLabPages)}</IonList>
              </>
            )}
            {!lab && loadingLab && <IonSkeletonText animated />}

            <IonList lines="none">
              <IonListHeader>{t("navigation.menuAccountHeader")}</IonListHeader>
              {!lab && !loadingLab && renderListItems(noLabPages)}
              {renderListItems(accountPages)}
              <IonMenuToggle autoHide={false}>
                <IonItem onClick={logout} button>
                  <MdLogout size="2em"></MdLogout>
                  <IonLabel>{t("logout")}</IonLabel>
                </IonItem>
              </IonMenuToggle>
            </IonList>
          </IonContent>
        </IonMenu>
      )}
      <IonRouterOutlet id="main">
        <AuthRoute
          path="/tabs"
          render={() => (
            <MainTabs
              myTasksSummary={myTasksSummary}
              activeCasesSummary={activeCasesSummary}
              pages={tabPages}
            />
          )}
        />
        {allPages
          .filter(page => !page.url.includes("/tabs"))
          .map((page, index) => (
            <AuthRoute
              key={index}
              path={page.url}
              component={page.component}
              exact
            />
          ))}

        <NonAuthRoute path="/login" component={Login} />
        <NonAuthRoute path="/register" component={Register} />
        <NonAuthRoute path="/forgot-password" component={ForgotPassword} />

        <Route path="/invitation" component={AcceptInvitation} />

        <Route path="/" component={InitialPath} exact />
        <Route component={NotFound} />
      </IonRouterOutlet>
    </IonSplitPane>
  );
};

export default Navigation;
