import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonButton
} from "@ionic/react";
import React, { useCallback, useRef, useState } from "react";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";
import LabForm, { convertToFormData, LabFormApi } from "./LabForm";
import useTranslation from "../../context/LanguageProvider";
import LabDto from "../../models/Lab";
import { Permission } from "../../models/Permissions";
import withPermission from "../../data/withPermission";
import useLab from "../../context/LabProvider";
import MiddleBox from "../MiddleBox";
import ButtonTextIcon from "../ButtonTextIcon";
import { useHistory } from "react-router";

const EditLab: React.FC = () => {
  const history = useHistory();
  const { apiPostMultiForm } = useApi();
  const { resetLab } = useLab();
  const { handleError, showSuccessToast } = useNotificationContext();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const formRef = useRef<LabFormApi>(null);

  const editLab = useCallback(
    (lab: LabDto) => {
      setLoading(true);
      return apiPostMultiForm<boolean>("lab/update", convertToFormData(lab))
        .then(() => {
          resetLab(lab);
          showSuccessToast(t("editLab.updated"));
        })
        .catch(handleError)
        .finally(() => {
          history.push("/settings");
          setLoading(false);
        });
    },
    [apiPostMultiForm, handleError, resetLab, showSuccessToast, t, history]
  );

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/settings" />
          </IonButtons>
          <IonTitle>{t("editLab.title")}</IonTitle>
          <IonButtons slot="primary">
            <IonButton
              disabled={loading}
              onClick={() => formRef.current?.submitForm()}
            >
              <ButtonTextIcon button="save" loading={loading} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <MiddleBox>
          <LabForm
            submitButtonText={t("save")}
            onSubmit={editLab}
            ref={formRef}
          />
        </MiddleBox>
      </IonContent>
    </IonPage>
  );
};

export default withPermission(EditLab, Permission.LabUpdate);
