import { faSave, faSpinner } from "@fortawesome/free-solid-svg-icons";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useNotificationContext } from "../../context/NotificationProvider";
import useApi from "../../data/Api";
import useTranslation from "../../context/LanguageProvider";
import useCurrency from "../../hooks/useCurrency";
import { ExtraDto } from "../../models/Product";
import ButtonTextIcon from "../ButtonTextIcon";
import Icon from "../Icon";
import ModalWrapper from "../ModalWrapper";

interface Props {
  onSuccess: (extra: ExtraDto) => void;
  onCancel: () => void;
  showModal: boolean;
  initialData: ExtraDto;
}

const ExtrasUpsertModal: React.FC<Props> = ({
  onSuccess,
  onCancel,
  initialData,
  showModal
}) => {
  const { apiPost } = useApi();
  const { handleError } = useNotificationContext();
  const { t, tPlaceholder } = useTranslation();
  const { currencySign } = useCurrency();

  const [isSubmitting, setSubmitting] = useState(false);
  const [extra, setExtra] = useState<ExtraDto>(initialData);

  useEffect(() => {
    setExtra(initialData);
  }, [initialData]);

  const upsert = () => {
    setSubmitting(true);
    apiPost<number>("extras/upsert", extra)
      .then(id => onSuccess({ ...extra, id }))
      .catch(handleError)
      .finally(() => setSubmitting(false));
  };

  return (
    <ModalWrapper
      modalOpened={showModal}
      dismiss={onCancel}
      modal="extrasUpsert"
    >
      <IonModal isOpen={showModal} onDidDismiss={onCancel}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={onCancel}>
                <ButtonTextIcon button="cancel" />
              </IonButton>
            </IonButtons>
            {initialData && (
              <IonTitle>
                {initialData.id > 0 ? t("extras.edit") : t("extras.new")}
              </IonTitle>
            )}
            <IonButtons slot="primary">
              <IonButton onClick={upsert} disabled={isSubmitting}>
                <ButtonTextIcon button="save" loading={isSubmitting} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonItem lines="none">
            <IonLabel position="stacked">{t("name")} *</IonLabel>
            <IonInput
              autocomplete="new-password"
              placeholder={t("extras.placeholder")}
              clearInput
              value={extra.name}
              onIonChange={e =>
                setExtra(p => ({ ...p, name: e.detail.value! }))
              }
            />
          </IonItem>
          <IonItem lines="none">
            <IonLabel position="stacked">
              {t("price") + " (" + currencySign + ")"}
            </IonLabel>
            <IonInput
              type="number"
              min="0.0"
              placeholder={tPlaceholder("price")}
              clearInput
              value={extra.price ? extra.price : undefined}
              onIonChange={e =>
                setExtra(p => ({ ...p, price: parseFloat(e.detail.value!) }))
              }
            />
          </IonItem>

          <IonButton
            class="ion-margin-top"
            color="secondary"
            expand="block"
            type="submit"
            disabled={!extra.name || isSubmitting}
            onClick={upsert}
          >
            {isSubmitting ? (
              <Icon spin icon={faSpinner} />
            ) : (
              <Icon icon={faSave} />
            )}
            {t("save")}
          </IonButton>
        </IonContent>
      </IonModal>
    </ModalWrapper>
  );
};

export default ExtrasUpsertModal;
