import React from "react";
import {
  faIdBadge,
  faIdCard,
  faExchangeAlt,
  faHospital,
  faHospitalAlt,
  faUserPlus
} from "@fortawesome/free-solid-svg-icons";
import { VscCalendar, VscSettings } from "react-icons/vsc";
import { MdOutlineDashboard, MdQueryStats } from "react-icons/md";
import Profile from "../profile/Profile";
import Cases from "../case/Cases";
import CalendarPage from "../calendar/CalendarPage";
import NewLab from "../lab/NewLab";
import AcceptInvitation from "../employee/AcceptInvitation";
import Doctors from "../doctor/Doctors";
import DoctorProfile from "../doctor/DoctorProfile";
import Employees from "../employee/Employees";
import EmployeeProfile from "../employee/EmployeeProfile";
import InviteEmployee from "../employee/InviteEmployee";
import ViewInvitation from "../employee/ViewInvitation";
import ChangeLab from "../lab/ChangeLab";
import { Permission } from "../../models/Permissions";
import EditLab from "../lab/EditLab";
import Case from "../case/Case";
import InvoiceView from "../invoice/InvoiceView";
import Settings from "../Settings";
import Invoices from "../invoice/Invoices";
import Tasks from "../task/Tasks";
import Task from "../task/Task";
import SchedulingTemplates from "../schedulingTemplate/SchedulingTemplates";
import ProductView from "../product/ProductView";
import Products from "../product/Products";
import Extras from "../product/Extras";
import ExtraView from "../product/ExtraView";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import Notifications from "../profile/Notifications";
import Stats from "../stats/Stats";
import Couriers from "../courier/Couriers";
import Materials from "../material/Materials";
import Material from "../material/Material";
import NewCase from "../case/NewCase";
import EditCase from "../case/EditCase";
import Dashboard from "../dashboard/Dashboard";
import CasesIcon from "../icons/CasesIcon";
import DoctorsIcon from "../icons/DoctorsIcon";
import InvoicesIcon from "../icons/InvoicesIcon";
import VersatileIcon, { IconKind } from "../VersatileIcon";
import MyLab from "../lab/MyLab";

export interface Page {
  url: string;
  icon?: IconKind;
  title: string;
  component: React.FC<any>;
  permission?: Permission;
}
// Edit my Profile, Add/edit Technicians, Clinic info/settings, Products, etc.
export const accountPages: Page[] = [
  {
    title: "settings",
    url: "/settings",
    icon: VscSettings,
    component: Settings
  }
];
export const noLabPages: Page[] = [
  {
    title: "newLab.title",
    url: "/new-lab",
    icon: faHospital,
    component: NewLab
  },
  {
    title: "joinLab.title",
    url: "invitation",
    icon: faUserPlus,
    component: AcceptInvitation
  }
];
// Tasks, Cases, doctors, etc.
export const myLabPages: Page[] = [
  {
    title: "dashboard.title",
    url: "/tabs/dashboard",
    icon: MdOutlineDashboard,
    component: Dashboard
    // permission: Permission.DashboardRead
  },
  // {
  //   title: "myTasks.title",
  //   url: "/tabs/myTasks",
  //   icon: faCheckCircle,
  //   component: MyTasks
  // },
  {
    title: "calendar",
    url: "/tabs/calendar",
    icon: VscCalendar,
    component: CalendarPage
    // permission: Permission.CalendarRead
  },
  {
    title: "cases.menuTitle",
    url: "/tabs/cases",
    icon: CasesIcon,
    component: Cases,
    permission: Permission.CasesTableRead
  },
  // {
  //   title: "workLog.title",
  //   url: "/work-logs",
  //   icon: faTasks,
  //   component: ProductionLogs,
  //   permission: Permission.ProductionLogsTableRead
  // },
  {
    title: "invoices.title",
    url: "/invoices",
    icon: InvoicesIcon,
    component: Invoices,
    permission: Permission.InvoicesTableRead
  },
  {
    title: "doctors.title",
    url: "/tabs/doctors",
    icon: DoctorsIcon,
    component: Doctors,
    permission: Permission.DoctorsTableRead
  },
  {
    title: "stats.title",
    url: "/stats",
    icon: MdQueryStats,
    component: Stats,
    permission: Permission.StatsRead
  }
];
// other pages that are not in the menus
const otherPages: Page[] = [
  {
    title: "employees.title",
    url: "/employees",
    icon: faIdBadge,
    component: Employees,
    permission: Permission.EmployeeRead
  },
  {
    title: "changeLab.title",
    url: "/change-lab",
    icon: faExchangeAlt,
    component: ChangeLab
  },
  {
    title: "newLab.title",
    url: "/new-lab",
    icon: faHospital,
    component: NewLab
  },
  {
    title: "profile.title",
    url: "/profile",
    icon: faIdCard,
    component: Profile
  },
  {
    title: "notifications.editNotifications",
    url: "/notifications",
    icon: faBell,
    component: Notifications
  },
  {
    title: "editLab.title",
    url: "/edit-lab",
    icon: faHospitalAlt,
    component: EditLab
  },
  {
    title: "myLab.title",
    url: "/my-lab",
    icon: faHospitalAlt,
    component: MyLab
  },
  {
    title: "case",
    url: "/case/:id",
    component: Case
  },
  {
    title: "case",
    url: "/edit-case/:id",
    component: EditCase
  },
  {
    title: "cases.new",
    url: "/new-case",
    component: NewCase
  },
  {
    title: "invoice",
    url: "/invoice/:id",
    component: InvoiceView
  },
  {
    title: "doctor",
    url: "/doctor/:id",
    component: DoctorProfile
  },
  {
    title: "employee",
    url: "/employee/:id",
    component: EmployeeProfile
  },
  {
    title: "inviteEmployee.title",
    url: "/invite-employee",
    component: InviteEmployee
  },
  {
    title: "viewInvitation.title",
    url: "/view-invitation/:id",
    component: ViewInvitation
  },
  {
    title: "task.titlePlural",
    url: "/tasks",
    component: Tasks
  },
  {
    title: "task.title",
    url: "/task/:id",
    component: Task
  },
  {
    title: "schedulingTemplate.titlePlural",
    url: "/scheduling-templates",
    component: SchedulingTemplates
  },
  {
    title: "products.title",
    url: "/product/:id",
    component: ProductView
  },
  {
    title: "products.title",
    url: "/products",
    component: Products
  },
  {
    title: "extras.title",
    url: "/extras",
    component: Extras
  },
  {
    title: "extras.title",
    url: "/extra/:id",
    component: ExtraView
  },
  {
    title: "courier.title",
    url: "/couriers",
    component: Couriers
  },
  {
    title: "materials.title",
    url: "/materials",
    component: Materials
  },
  {
    title: "material",
    url: "/material/:id",
    component: Material
  }
];
// merge for router outlet

export const allPages: Page[] = myLabPages.concat(accountPages, otherPages);
export const doctorHiddenPages: string[] = ["/tabs/myTasks"];

const PageIcon: React.FC<Page> = page => (
  <>{page.icon && <VersatileIcon icon={page.icon} {...page} />}</>
);

export { PageIcon };
