import React, { useCallback, useEffect, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonModal,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";
import useTranslation from "../../context/LanguageProvider";
import ButtonTextIcon from "../ButtonTextIcon";
import ModalWrapper from "../ModalWrapper";
import useLab from "../../context/LabProvider";
import { CaseHistory, CaseHistoryFormated } from "../../models/Case";
import DataTable, { TableColumn } from "react-data-table-component";
import useDate from "../../hooks/useDate";
import { DoctorInLabDto } from "../../models/Doctor";

interface Props {
  id: number;
  onCancel: () => void;
  showCaseHistoryModal: boolean;
  doctorInlab: DoctorInLabDto;
}
const CaseHistoryModal: React.FC<Props> = ({
  id,
  onCancel,
  showCaseHistoryModal,
  doctorInlab
}) => {
  const { handleError } = useNotificationContext();
  const { t } = useTranslation();
  const [historyData, setHistoryData] = useState<CaseHistory[]>();
  const [historyDataFormated, setHistoryDataFormated] =
    useState<CaseHistoryFormated[]>();
  const { employees } = useLab();

  const { apiGet } = useApi();
  const { toDateTimeString } = useDate();

  const getHistory = useCallback(
    () =>
      apiGet<CaseHistory[]>(`case/history?caseId=${id}`)
        .then(data => setHistoryData(data))
        .catch(handleError),
    [apiGet, handleError, id]
  );

  useEffect(() => {
    setHistoryDataFormated(
      historyData?.map(d => {
        const employee = employees.find(e => e.id === d.userId);
        return {
          ...d,
          user: employee
            ? employee?.firstName + " " + employee?.lastName
            : doctorInlab.name
        };
      })
    );
  }, [doctorInlab.name, employees, historyData]);

  useEffect(() => {
    if (showCaseHistoryModal) getHistory();
  }, [getHistory, showCaseHistoryModal]);

  const columns: TableColumn<CaseHistoryFormated>[] = [
    {
      name: t("caseHistory.action"),
      sortable: false,
      grow: 3,
      cell: row => (
        <IonItem key={row.id} lines="none" class="ion-no-padding">
          <IonLabel>{t("caseHistory.actions." + row.action)}</IonLabel>
        </IonItem>
      )
    },
    {
      name: t("user"),
      sortable: false,
      grow: 1,
      cell: row => row.user
    },
    {
      name: t("time"),
      sortable: true,
      grow: 1,
      cell: row => toDateTimeString(row.created)
    }
  ];
  return (
    <ModalWrapper
      modalOpened={showCaseHistoryModal}
      dismiss={onCancel}
      modal="caseHistory"
    >
      <IonModal isOpen={showCaseHistoryModal} onDidDismiss={onCancel}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={onCancel}>
                <ButtonTextIcon button="cancel" />
              </IonButton>
            </IonButtons>
            <IonTitle>{t("caseHistory.title")}</IonTitle>
            <IonButtons slot="primary">
              <IonButton onClick={onCancel}>{t("ok")}</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          {historyDataFormated && (
            <DataTable
              keyField="id"
              data={historyDataFormated}
              columns={columns}
              noHeader
              noDataComponent={t("noRecords")}
              paginationComponentOptions={{
                rowsPerPageText: t("dataTable.rowsPerPageText"),
                rangeSeparatorText: t("dataTable.rangeSeparatorText")
              }}
            />
          )}
        </IonContent>
      </IonModal>
    </ModalWrapper>
  );
};

export default CaseHistoryModal;
