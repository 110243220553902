import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithPopup,
  sendEmailVerification,
  GoogleAuthProvider,
  FacebookAuthProvider,
  User as FbUser
} from "firebase/auth";
import {
  AddPrefixToKeys,
  doc,
  getDoc,
  initializeFirestore,
  onSnapshot,
  runTransaction,
  setDoc,
  DocumentData,
  WithFieldValue,
  PartialWithFieldValue
} from "firebase/firestore";
import { getMessaging, isSupported } from "firebase/messaging";
import config from "./firebaseConfig";
import {
  unpackPermissionsFromString,
  containsPermission
} from "../models/Permissions";
import { User } from "../models/User";
import { APP_URL } from "./Constants";

const app = initializeApp(config);

const firebaseAuth = getAuth(app);
const notificationsSupported = isSupported();
const firebaseMessaging = getMessaging(app);
// fix for error: @firebase/firestore: Firestore (8.2.5): Connection WebChannel transport errored...
const firestore = initializeFirestore(app, {
  experimentalForceLongPolling: true
});

const signInWithGoogle = () =>
  signInWithPopup(firebaseAuth, new GoogleAuthProvider());
const signInWithFacebook = () =>
  signInWithPopup(firebaseAuth, new FacebookAuthProvider());

const onFirestoreSnapshot = (
  collection: string,
  id: number,
  onData: (data?: DocumentData) => void
) => {
  const docRef = doc(firestore, collection, id.toString());
  return onSnapshot(
    docRef,
    {
      includeMetadataChanges: true
    },
    doc => {
      if (doc.exists()) {
        const dbData = doc.data();
        onData(dbData);
      } else {
        doc.data(undefined); // will be undefined in this case
        console.log("No such document!", collection, id);
      }
    }
  );
};
const onFirestoreSnapshotArray = <T>(
  collection: string,
  id: number,
  onData: (data: T[]) => void
) =>
  onFirestoreSnapshot(collection, id, dbData =>
    onData(dbData ? Object.values(dbData).map(t => t as T) : [])
  );

const updateFirestore = (
  collection: string,
  id: number,
  data: {
    [x: string]: any;
  } & AddPrefixToKeys<string, any>
) =>
  runTransaction(firestore, async transaction => {
    const docRef = doc(firestore, collection, id.toString());
    const docSnapshot = await transaction.get(docRef);
    if (docSnapshot.exists()) {
      transaction.update(docRef, data);
    } else {
      // Handle the case where the document does not exist.
      transaction.set(docRef, data);
    }
  });

const getFirestoreDoc = (collection: string, id: number) =>
  getDoc(doc(firestore, collection, id.toString()));

const setFirestoreDoc = (
  collection: string,
  id: number,
  data: WithFieldValue<DocumentData>
) => setDoc(doc(firestore, collection, id.toString()), data);
const setFirestoreDocMerge = (
  collection: string,
  id: number,
  data: PartialWithFieldValue<DocumentData>
) => setDoc(doc(firestore, collection, id.toString()), data, { merge: true });

const currentUser = () => firebaseAuth.currentUser;

const PackedPermissionClaimType = "https://rashtan-smile.com/permissions";
const LabIdClaimType = "https://rashtan-smile.com/lab";
const UserIdClaimType = "https://rashtan-smile.com/user";
const DoctorIdClaimType = "https://rashtan-smile.com/doctor";

const tokenAsString = (token: string | object | undefined) => {
  if (typeof token === "string") return token;
  if (typeof token === "object") return token.toString();
  return "";
};

const tokenAsNumber = (token: string | object | undefined) => {
  if (typeof token === "string") return parseInt(token);
  if (typeof token === "number") return token;
  if (typeof token === "object") return parseInt(token.toString());
  return undefined;
};

const extractCustomClaims = async (user: FbUser, forceRefresh?: boolean) => {
  const token = await user.getIdTokenResult(forceRefresh);

  const packedPermissions = tokenAsString(
    token.claims[PackedPermissionClaimType]
  );
  const labId = tokenAsNumber(token.claims[LabIdClaimType]);
  const userId = tokenAsNumber(token.claims[UserIdClaimType]);
  const doctorId = tokenAsNumber(token.claims[DoctorIdClaimType]);

  return { packedPermissions, labId, userId, doctorId };
};

const hasIdInToken = async (user: FbUser) => {
  const { userId } = await extractCustomClaims(user);

  return userId ? true : false;
};

const extractUserInfo: (user: FbUser) => Promise<User> = async user => {
  // force refresh the token to get the latest user info
  const { packedPermissions, labId, userId, doctorId } =
    await extractCustomClaims(user, true);
  const permissions = unpackPermissionsFromString(packedPermissions);
  return {
    name: user.displayName,
    email: user.email,
    emailVerified:
      user.providerData[0]?.providerId === "facebook.com"
        ? true
        : user.emailVerified,
    userId,
    permissions,
    doctorId: doctorId,
    labId,
    sendEmailVerification: (code: string | null) => {
      // we take the current user, because the user object that was passed in might be unavailable
      const u = currentUser();
      if (!u || u === null) return Promise.resolve();

      return sendEmailVerification(u, {
        url: code ? `${APP_URL}invitation?code=${code}` : `${APP_URL}new-lab/`
        // TODO: Add options for opening Continue button in the app
      });
    },
    getIdToken: b => {
      // we take the current user, because the user object that was passed in might be unavailable
      const u = currentUser();
      if (!u || u === null) return Promise.resolve(undefined);
      return u.getIdToken(b);
    },
    hasPermission: p => containsPermission(permissions, p)
  };
};

const CaseChatCollection = "org_caseChat";

export {
  firebaseMessaging,
  notificationsSupported,
  firebaseAuth,
  currentUser,
  extractUserInfo,
  hasIdInToken,
  onFirestoreSnapshot,
  onFirestoreSnapshotArray,
  getFirestoreDoc,
  setFirestoreDoc,
  setFirestoreDocMerge,
  updateFirestore,
  signInWithGoogle,
  signInWithFacebook,
  CaseChatCollection
};
