import React, { useEffect } from "react";
import { useForm, FormProvider, DefaultValues, Path } from "react-hook-form";
import { IonButton } from "@ionic/react";
import { faSave, faSpinner } from "@fortawesome/free-solid-svg-icons";
import Icon from "../Icon";

interface Props<TFormValues> {
  initialData?: DefaultValues<TFormValues>;
  alwaysEnableSubmitButton?: boolean;
  submitButtonText: string;
  submitButtonIcon?: React.ReactNode;
  children: React.ReactNode | React.ReactNode[];
  ignoreFields?: Path<TFormValues>[];
  onSubmit: (v: TFormValues) => Promise<void> | void;
  exposeSubmit?: (submit: () => void) => void;
  setSubmitting?: (isSubmitting: boolean) => void;
}

const Form = <TFormValues extends Record<string, any> = Record<string, any>>({
  initialData,
  alwaysEnableSubmitButton,
  submitButtonText,
  submitButtonIcon,
  children,
  ignoreFields,
  onSubmit,
  exposeSubmit,
  setSubmitting
}: Props<TFormValues>) => {
  const methods = useForm<TFormValues>({
    defaultValues: initialData,
    mode: "all"
  });

  const {
    handleSubmit,
    unregister,
    formState: { isSubmitting, errors }
  } = methods;

  useEffect(() => {
    if (exposeSubmit)
      exposeSubmit(() => handleSubmit(d => onSubmit(d as TFormValues)));
  }, [exposeSubmit]);

  useEffect(() => {
    if (ignoreFields && ignoreFields.length > 0) {
      unregister(ignoreFields);
    }
  }, [ignoreFields, unregister]);

  useEffect(() => {
    if (setSubmitting) setSubmitting(isSubmitting);
  }, [isSubmitting, setSubmitting]);

  return (
    <FormProvider {...methods}>
      <form
        autoComplete="off"
        onSubmit={handleSubmit(d => onSubmit(d as TFormValues))}
      >
        {children}

        <IonButton
          class="ion-margin"
          color="secondary"
          expand="block"
          type="submit"
          disabled={
            isSubmitting ||
            (!alwaysEnableSubmitButton && Object.keys(errors).length !== 0)
          }
        >
          {isSubmitting ? (
            <Icon spin icon={faSpinner} />
          ) : (
            submitButtonIcon ?? <Icon icon={faSave} />
          )}
          {submitButtonText}
        </IonButton>
      </form>
    </FormProvider>
  );
};

export default Form;
