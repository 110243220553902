import React, { Suspense } from "react";
import {
  IonApp,
  IonAlert,
  IonToast,
  IonSpinner,
  setupIonicReact
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "antd/dist/antd.css";
import "./theme/variables.css";
import "./theme/app.css";

import { AuthProvider } from "./context/AuthProvider";
import {
  NotificationProvider,
  NotificationConsumer
} from "./context/NotificationProvider";
import Navigation from "./components/navigation/Navigation";
import { LabProvider } from "./context/LabProvider";
import { LanguageProvider } from "./context/LanguageProvider";
import { PushProvider } from "./data/pushNotifications";
import AntdContext, { AntdProvider } from "./context/AntdProvider";
import { ConfigProvider } from "antd";

setupIonicReact({
  rippleEffect: true,
  mode: "md"
});

const App: React.FC = () => (
  <Suspense fallback={<IonSpinner />}>
    <LanguageProvider>
      <PushProvider>
        <NotificationProvider>
          <AntdProvider>
            <AuthProvider>
              <LabProvider>
                <AntdContext.Consumer>
                  {locale => (
                    <ConfigProvider locale={locale.locale.lang}>
                      <IonApp>
                        <NotificationConsumer>
                          {({ alert, toast, hideAlert, hideToast }) => {
                            return (
                              <>
                                <IonAlert
                                  isOpen={alert !== undefined}
                                  onDidDismiss={hideAlert}
                                  header={alert?.title}
                                  message={alert?.message}
                                  buttons={alert?.buttons}
                                />
                                <IonToast
                                  isOpen={toast !== undefined}
                                  onDidDismiss={hideToast}
                                  message={toast?.message}
                                  duration={toast?.duration}
                                  color={toast?.color}
                                  buttons={[
                                    {
                                      text: "OK",
                                      role: "cancel"
                                    }
                                  ]}
                                />
                              </>
                            );
                          }}
                        </NotificationConsumer>
                        <IonReactRouter>
                          <Navigation />
                        </IonReactRouter>
                      </IonApp>
                    </ConfigProvider>
                  )}
                </AntdContext.Consumer>
              </LabProvider>
            </AuthProvider>
          </AntdProvider>
        </NotificationProvider>
      </PushProvider>
    </LanguageProvider>
  </Suspense>
);

export default App;
