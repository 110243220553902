import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonContent,
  IonHeader,
  IonImg,
  IonPage,
  IonSkeletonText,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import React from "react";
import useLab from "../../context/LabProvider";
import useTranslation from "../../context/LanguageProvider";
import ValueLabel from "../ValueLabel";
import { currencies } from "../../models/Currency";
import Can from "../Can";
import { Permission } from "../../models/Permissions";
import MiddleBox from "../MiddleBox";

const MyLab: React.FC = () => {
  const { t } = useTranslation();
  const { lab, loadingLab, logoImg } = useLab();

  const currency = currencies.find(c => c.code === lab?.currency);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/settings" />
          </IonButtons>
          <IonTitle>{t("newLab.title")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        {loadingLab || !lab ? (
          <IonSkeletonText animated />
        ) : (
          <MiddleBox>
            <IonCard>
              <IonCardHeader color="primary">
                {logoImg && (
                  <IonImg
                    className="lab-logo"
                    src={logoImg}
                    alt="assets/img/logo.png"
                  />
                )}
                <IonCardTitle>{lab.name}</IonCardTitle>
              </IonCardHeader>

              <IonCardContent>
                <ValueLabel title={t("phone")}>{lab.phone}</ValueLabel>
                <ValueLabel title={t("website")}>{lab.website}</ValueLabel>
                <ValueLabel title={t("currency")}>
                  ({currency?.symbol}) {currency?.name}
                </ValueLabel>
                <ValueLabel title={t("notation")}>
                  {t(`dentalNotations.${lab.dentalNotation}`)}
                </ValueLabel>
                <ValueLabel title={t("taxRate")}>{lab.taxRate}</ValueLabel>
                <ValueLabel title={t("address")}>{lab.address}</ValueLabel>
              </IonCardContent>

              <Can permission={Permission.LabUpdate}>
                <IonButton
                  fill="outline"
                  color="primary"
                  routerLink={`/edit-lab`}
                >
                  {t("edit")}
                </IonButton>
              </Can>
            </IonCard>
          </MiddleBox>
        )}
      </IonContent>
    </IonPage>
  );
};

export default MyLab;
