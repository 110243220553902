import {
  faPlus,
  faSave,
  faSpinner,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import React, { useCallback, useEffect, useState } from "react";
import { useNotificationContext } from "../../context/NotificationProvider";
import useApi from "../../data/Api";
import { parseNumber } from "../../data/numberHelpers";
import useTranslation from "../../context/LanguageProvider";
import { CaseViewDto } from "../../models/Case";
import ButtonTextIcon from "../ButtonTextIcon";
import Icon from "../Icon";
import { Permission } from "../../models/Permissions";
import ModalWrapper from "../ModalWrapper";
import useLab from "../../context/LabProvider";
import MaterialUpsertModal from "../material/MaterialUpsertModal";
import Can from "../Can";

interface Props {
  onSuccess: (id: number) => void;
  onCancel: () => void;
  showModal: boolean;
  initialData: CaseViewDto;
}

const initialMaterial = {
  id: 0,
  name: "",
  code: "",
  unit: "#"
};

type ChangeEventType = CustomEvent<{ value: string | null | undefined }>;

const CaseMaterialsUpsertModal: React.FC<Props> = ({
  onSuccess,
  onCancel,
  initialData,
  showModal
}) => {
  const { apiPost } = useApi();
  const { handleError } = useNotificationContext();
  const { t, tPlaceholder } = useTranslation();
  const { materials } = useLab();

  const [showExtrasUpsertModal, setShowMaterialUpsertModal] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [$case, setCase] = useState<CaseViewDto>(initialData);

  useEffect(() => {
    setCase(initialData);
  }, [initialData]);

  const upsert = useCallback(() => {
    setSubmitting(true);
    apiPost<number>("case/saveMaterials", $case)
      .then(onSuccess)
      .catch(handleError)
      .finally(() => setSubmitting(false));
  }, [apiPost, handleError, $case]);

  const addMaterial = useCallback((id: number) => {
    id &&
      setCase(p => ({
        ...p,
        materials: [...p.materials, { materialId: id, quantity: 1 }]
      }));
  }, []);

  const onQuantityChange = useCallback(
    (id: number) => (event: ChangeEventType) => {
      setCase(Case => ({
        ...Case,
        materials: Case.materials.map(m =>
          m.materialId === id
            ? { ...m, quantity: parseNumber(event.detail.value) ?? 0 }
            : m
        )
      }));
    },
    []
  );

  const deleteMaterial = (i: number) =>
    setCase(Case => ({
      ...Case,
      materials: Case.materials.filter((_, index) => index !== i)
    }));

  const unusedMaterials = materials.filter(
    material => !$case.materials.some(m => m.materialId === material.id)
  );

  return (
    <ModalWrapper
      modalOpened={showModal}
      dismiss={onCancel}
      modal="materialUpsert"
    >
      <IonModal isOpen={showModal} onDidDismiss={onCancel}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={onCancel}>
                <ButtonTextIcon button="cancel" />
              </IonButton>
            </IonButtons>
            <IonTitle>{t("materials.title")}</IonTitle>
            <IonButtons slot="primary">
              <IonButton onClick={upsert} disabled={isSubmitting}>
                <ButtonTextIcon button="save" loading={isSubmitting} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonGrid>
            <IonRow>
              {$case.materials.map((material, i) => {
                const { name, unit } =
                  materials.find(m => m.id === material.materialId) ??
                  initialMaterial;
                return (
                  <IonItem lines="none">
                    <IonLabel position="stacked">
                      {name} ({unit})
                    </IonLabel>
                    <IonInput
                      type="number"
                      inputMode="numeric"
                      step="1"
                      min="1"
                      value={material.quantity}
                      placeholder={tPlaceholder("quantity")}
                      onIonChange={onQuantityChange(material.materialId)}
                    />
                    <IonButton
                      slot="end"
                      // size="small"
                      color="danger"
                      onClick={() => deleteMaterial(i)}
                      style={{ marginTop: 46 }}
                      className="btn-input-delete"
                    >
                      <span className="removeBtnIconMargin">
                        <Icon icon={faTrash} />
                      </span>
                    </IonButton>
                  </IonItem>
                );
              })}
            </IonRow>
            <IonRow>
              <IonCol size-xs="6" size-md="8" hidden={!unusedMaterials.length}>
                <IonSelect
                  interface="action-sheet"
                  placeholder={t("materials.selectMaterial")}
                  value={null}
                  onIonChange={e => addMaterial(e.detail.value)}
                >
                  {unusedMaterials.map(material => (
                    <IonSelectOption key={material.id} value={material.id}>
                      {material.name}
                    </IonSelectOption>
                  ))}
                </IonSelect>
              </IonCol>
              <IonCol size-xs="6" size-md="4">
                <Can permission={Permission.MaterialsCreate}>
                  <IonButton
                    color="primary"
                    fill="outline"
                    expand="block"
                    type="submit"
                    disabled={isSubmitting}
                    onClick={() => setShowMaterialUpsertModal(true)}
                  >
                    <Icon icon={faPlus} />
                    {t("materials.new")}
                  </IonButton>
                </Can>
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonButton
            class="ion-margin-top"
            color="secondary"
            expand="block"
            type="submit"
            disabled={isSubmitting || !$case.materials.length}
            onClick={upsert}
          >
            {isSubmitting ? (
              <Icon spin icon={faSpinner} />
            ) : (
              <Icon icon={faSave} />
            )}
            {t("save")}
          </IonButton>

          <Can permission={Permission.MaterialsCreate}>
            <MaterialUpsertModal
              showModal={showExtrasUpsertModal}
              initialData={initialMaterial}
              onCancel={() => setShowMaterialUpsertModal(false)}
              onSuccess={id => {
                setShowMaterialUpsertModal(false);
                addMaterial(id);
              }}
            />
          </Can>
        </IonContent>
      </IonModal>
    </ModalWrapper>
  );
};

export default CaseMaterialsUpsertModal;
