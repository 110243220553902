import React from "react";
import { RegisterOptions } from "react-hook-form";
import FormItem from "./FormItem";
import { Select } from "antd";

export interface FormItemProps {
  name: string;
  label: string;
  rules?: RegisterOptions;
  hidden?: boolean;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  dateAndTime?: boolean;
  minuteStep?: number;
}

const FormAntdDate: React.FC<FormItemProps> = ({
  name,
  label,
  rules,
  hidden,
  disabled,
  ...props
}) => (
  <FormItem
    name={name}
    label={label}
    rules={rules}
    hidden={hidden}
    disabled={disabled}
    input={formProps => <Select size="large" {...formProps} {...props} />}
  />
);

export default FormAntdDate;
