import React, { useCallback, useEffect, useRef, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router";
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonSegment,
  IonLabel,
  IonContent,
  useIonViewWillEnter,
  IonButton,
  IonPopover,
  IonList,
  IonItem
} from "@ionic/react";
import { RefresherEventDetail } from "@ionic/core/components";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";
import { CaseStatus, CaseViewDto } from "../../models/Case";
import CaseInfoView from "./CaseInfoView";
import useTranslation from "../../context/LanguageProvider";
import withPermission from "../../data/withPermission";
import { Permission } from "../../models/Permissions";
import CaseInvoices from "./CaseInvoices";
import Can from "../Can";
import ButtonTextIcon from "../ButtonTextIcon";
import { useAuthContext } from "../../context/AuthProvider";
import Icon from "../Icon";
import {
  faDownload,
  faEdit,
  faEllipsisV,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import Refresher from "../Refresher";
import usePushNotifications from "../../data/pushNotifications";
import CaseHistoryModal from "./CaseHistoryModal";
import { Button } from "antd/lib/radio";
import { DoctorInLabDto } from "../../models/Doctor";
import ShowForLanguage from "../navigation/ShowForLanguage";
import HideForDoctor from "../navigation/HideForDoctor";
import useLab from "../../context/LabProvider";
import CaseCourierModal from "./CaseCourierModal";
import CaseMaterials from "./CaseMaterials";
import SegmentButtonTextIcon from "../SegmentButtonTextIcon";
import CaseName from "./CaseName";
import { AiOutlineInfoCircle, AiOutlineMessage } from "react-icons/ai";
import InvoicesIcon from "../icons/InvoicesIcon";
import { SlPuzzle } from "react-icons/sl";
import CaseChat from "./CaseChat";
import {
  CaseChatCollection,
  getFirestoreDoc,
  onFirestoreSnapshot
} from "../../data/firebase";

interface Props
  extends RouteComponentProps<{
    id: string;
  }> {
  id: 0;
}

const Case: React.FC<Props> = ({ match }) => {
  const [segment, setSegment] = useState<
    "info" | "materials" | "invoices" | "chat"
  >("info");
  const [unreadMessageFlag, setUnreadMessageFlag] = useState(false);
  const [messagesCount, setMessagesCount] = useState(0);
  const history = useHistory();
  const [showCaseCourierModal, setShowCaseCourierModal] = useState(false);
  const { apiGet, apiDelete, apiBlobDownload, apiGetBlob } = useApi();
  const { couriers } = useLab();
  const { handleError, showAlert, showSuccessToast } = useNotificationContext();
  const { t } = useTranslation();

  const pdfFrame = useRef<HTMLIFrameElement>(null);
  const [downloading, setDownloading] = useState(false);
  const [doctorInLab, setDoctorInLab] = useState<DoctorInLabDto>();
  const [showCaseHistoryModal, setShowCaseHistoryModal] = useState(false);
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined
  });
  const [getSvg, setGetSvg] = useState<() => string | undefined>(
    () => undefined
  );
  const [$case, setCase] = useState<CaseViewDto>();
  const { user } = useAuthContext();
  const { userConsent, notificationsSupported, checkToken } =
    usePushNotifications();

  const getCase = useCallback(
    (refreshEvent?: CustomEvent<RefresherEventDetail>) =>
      apiGet<CaseViewDto>(`case/get?id=${match.params.id}`)
        .then(newCase => {
          if (couriers.length > 0) {
            if (user?.doctorId === undefined) {
              if (
                newCase.id === $case?.id &&
                $case?.status !== CaseStatus.ReadyForShippingToDr &&
                newCase.status === CaseStatus.ReadyForShippingToDr
              )
                setShowCaseCourierModal(true);
            } else if (
              newCase.id === $case?.id &&
              $case?.status !== CaseStatus.ReadyForShippingToLab &&
              newCase.status === CaseStatus.ReadyForShippingToLab
            )
              setShowCaseCourierModal(true);
          } else {
          }

          return newCase;
        })
        .then(newCase => setCase(newCase))
        .catch(handleError)
        .finally(() => {
          if (refreshEvent && refreshEvent.detail)
            refreshEvent.detail.complete();
        }),
    [
      apiGet,
      match.params.id,
      handleError,
      couriers,
      user?.doctorId,
      $case?.id,
      $case?.status
    ]
  );

  useIonViewWillEnter(() => getCase());

  useEffect(() => {
    if (!$case) return;

    if (notificationsSupported && userConsent === "default")
      checkToken().catch(handleError);
  }, [$case, notificationsSupported, userConsent, checkToken, handleError]);

  const dismissPopover = () =>
    setShowPopover({ showPopover: false, event: undefined });

  const printBlob = useCallback((blob: Blob) => {
    if (!pdfFrame.current) return;
    // wait until the document loads
    pdfFrame.current.onload = (ev: Event) => {
      ev.preventDefault();
      pdfFrame.current?.contentWindow?.print();
    };

    const objectURL = URL.createObjectURL(blob);
    pdfFrame.current.src = objectURL;
    URL.revokeObjectURL(objectURL);
  }, []);

  const saveAsPdf = useCallback(() => {
    setDownloading(true);
    apiBlobDownload("POST", `case/SaveAsPdf`, {
      id: match.params.id,
      arches: getSvg()
    })
      .catch(handleError)
      .finally(() => setDownloading(false));
    dismissPopover();
  }, [apiBlobDownload, getSvg, handleError, match.params.id]);

  const printLabSlip = useCallback(() => {
    setDownloading(true);
    apiGetBlob("POST", `case/SaveAsPdf`, {
      id: match.params.id,
      arches: getSvg()
    })
      .then(({ blob }) => printBlob(blob))
      .catch(handleError)
      .finally(() => setDownloading(false));
    dismissPopover();
  }, [apiGetBlob, getSvg, handleError, printBlob, match.params.id]);

  const cocAsPdf = useCallback(() => {
    setDownloading(true);
    apiBlobDownload("GET", `case/DownloadCoC?id=${match.params.id}`, undefined)
      .catch(handleError)
      .finally(() => setDownloading(false));
    dismissPopover();
  }, [apiBlobDownload, handleError, match.params.id]);

  const labelAsPdf = useCallback(() => {
    setDownloading(true);
    apiGetBlob("GET", `case/DownloadLabel?id=${match.params.id}`, undefined)
      .then(({ blob }) => printBlob(blob))
      .catch(handleError)
      .finally(() => setDownloading(false));
    dismissPopover();
  }, [apiGetBlob, handleError, printBlob, match.params.id]);

  useEffect(() => {
    if ($case?.doctorId)
      apiGet<DoctorInLabDto>(`doctor/getLabAccess?id=${$case?.doctorId}`)
        .then(setDoctorInLab)
        .catch(handleError);
  }, [$case?.doctorId, apiGet, handleError]);

  useEffect(() => {
    if (user && $case?.id) {
      getFirestoreDoc(CaseChatCollection, $case.id)
        .then(doc => {
          if (!doc.exists()) {
            setMessagesCount(0);
            setUnreadMessageFlag(false);
          }
        })
        .then(_ => {
          onFirestoreSnapshot(CaseChatCollection, $case.id, data => {
            if (data && data.messages.length) {
              setMessagesCount(data.messages.length);
              data.readBy.includes(user.userId?.toString())
                ? setUnreadMessageFlag(false)
                : setUnreadMessageFlag(true);
            }
          });
        });
    }
  }, [$case?.id, user, user?.userId]);

  const onDeleteCaseClicked = useCallback(() => {
    $case?.id &&
      showAlert({
        title: t("warning"),
        message: t("cases.confirmDelete"),
        buttons: [
          {
            text: t("cancel"),
            role: "cancel"
          },
          {
            text: t("delete"),
            handler: () => {
              apiDelete("case/delete?id=" + $case.id)
                .then(() => {
                  history.replace("/");
                  showSuccessToast(t("cases.caseDeleted"));
                })
                .catch(handleError);
            }
          }
        ]
      });
    dismissPopover();
  }, [
    $case?.id,
    history,
    apiDelete,
    handleError,
    showAlert,
    showSuccessToast,
    t
  ]);

  const renderSwitch = () => {
    if (!$case) return;

    switch (segment) {
      case "info":
        return (
          <CaseInfoView
            $case={$case}
            onChange={getCase}
            showEdit={() => history.push(`/edit-case/${match.params.id}`)}
            exposeGetSvg={s => setGetSvg(() => s)}
          />
        );
      case "materials":
        return <CaseMaterials $case={$case} onChange={getCase} />;
      case "invoices":
        return <CaseInvoices $case={$case} />;
      case "chat":
        return <CaseChat $case={$case} doctorInLab={doctorInLab} />;
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" />
          </IonButtons>
          <IonTitle className="case-page-title">
            {$case && (
              <>
                <CaseName doctorName={$case.doctor.name} {...$case} />
              </>
            )}
          </IonTitle>
          <IonButtons slot="primary">
            {$case && (
              <>
                <Can permission={Permission.CaseUpdate}>
                  <IonButton
                    hidden={
                      user?.doctorId !== undefined &&
                      $case.status !== CaseStatus.PendingAccept
                    }
                    className="ion-margin-start ion-hide-sm-down"
                    routerLink={"/edit-case/" + $case.id}
                  >
                    <ButtonTextIcon xlHide button="edit" />
                  </IonButton>
                </Can>
                <IonButton
                  className="ion-hide-sm-down"
                  hidden={segment !== "info"}
                  // hidden
                  onClick={saveAsPdf}
                  disabled={downloading}
                >
                  <ButtonTextIcon
                    xlHide
                    button="download"
                    loading={downloading}
                  />
                </IonButton>
                <HideForDoctor>
                  <ShowForLanguage languages={["ro"]} currencies={["RON"]}>
                    <IonButton
                      className="ion-hide-sm-down"
                      hidden={segment !== "info"}
                      // hidden
                      onClick={cocAsPdf}
                      disabled={downloading}
                    >
                      <ButtonTextIcon
                        xlHide
                        button="certificate"
                        loading={downloading}
                      />
                    </IonButton>
                    <IonButton
                      className="ion-hide-sm-down"
                      hidden={segment !== "info"}
                      // hidden
                      onClick={labelAsPdf}
                      disabled={downloading}
                    >
                      <ButtonTextIcon
                        xlHide
                        button="label"
                        loading={downloading}
                      />
                    </IonButton>
                    <IonButton
                      className="ion-hide-sm-down"
                      hidden={segment !== "info"}
                      // hidden
                      onClick={printLabSlip}
                      disabled={downloading}
                    >
                      <ButtonTextIcon
                        xlHide
                        button="print"
                        loading={downloading}
                      />
                    </IonButton>
                  </ShowForLanguage>
                </HideForDoctor>
                <Can permission={Permission.CaseDelete}>
                  <IonButton
                    className="ion-margin-start ion-hide-sm-down"
                    onClick={onDeleteCaseClicked}
                  >
                    <ButtonTextIcon
                      xlHide
                      button="delete"
                      iconClass="dangerColor"
                    />
                  </IonButton>
                </Can>

                <IonButton
                  className="ion-hide-sm-up"
                  onClick={(e: any) => {
                    e.persist();
                    setShowPopover({ showPopover: true, event: e });
                  }}
                >
                  <Icon icon={faEllipsisV} />
                </IonButton>
              </>
            )}
          </IonButtons>
        </IonToolbar>
        <IonToolbar className="header_toolbar2">
          <IonSegment
            hidden={$case?.status === CaseStatus.PendingAccept}
            onIonChange={e => setSegment(e.detail.value as any)}
            value={segment}
          >
            <SegmentButtonTextIcon
              value="info"
              title={t("info")}
              icon={AiOutlineInfoCircle}
            />
            <SegmentButtonTextIcon
              value="chat"
              title={t("chat.title")}
              icon={AiOutlineMessage}
              count={messagesCount}
              iconClass={unreadMessageFlag ? "dangerColor" : ""}
            />
            <Can permission={Permission.InvoiceRead}>
              <SegmentButtonTextIcon
                value="invoices"
                title={t("invoices.title")}
                icon={InvoicesIcon}
              />
            </Can>
            <Can permission={Permission.MaterialsRead}>
              <SegmentButtonTextIcon
                value="materials"
                title={t("materials.title")}
                icon={SlPuzzle}
              />
            </Can>
          </IonSegment>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <Refresher onRefresh={e => getCase(e)} />
        <iframe title="pdf-frame" id="pdf-frame" ref={pdfFrame} hidden />
        {renderSwitch()}
        <Button
          style={{ border: "none", float: "right", marginTop: 30 }}
          onClick={() => setShowCaseHistoryModal(true)}
        >
          {t("caseHistory.title")}
        </Button>
      </IonContent>

      {$case && doctorInLab && (
        <>
          <CaseHistoryModal
            id={$case.id}
            showCaseHistoryModal={showCaseHistoryModal}
            onCancel={() => setShowCaseHistoryModal(false)}
            doctorInlab={doctorInLab}
          />
          <CaseCourierModal
            caseId={$case.id}
            caseStatus={$case.status}
            drAddress={$case.doctor.address ?? ""}
            drClinicName={$case.doctor.clinicName ?? ""}
            drName={$case.doctor.name}
            onCancel={() => {
              setShowCaseCourierModal(false);
            }}
            showCaseCourierModal={showCaseCourierModal}
          />
          <IonPopover
            event={popoverState.event}
            isOpen={popoverState.showPopover}
            onDidDismiss={dismissPopover}
          >
            <IonList className="ion-padding" lines="inset">
              <Can permission={Permission.CaseUpdate}>
                <IonItem
                  routerLink={"/edit-case/" + $case.id}
                  onClick={() =>
                    setShowPopover({ showPopover: false, event: undefined })
                  }
                >
                  <Icon icon={faEdit} />
                  <IonLabel>{t("edit")}</IonLabel>
                </IonItem>
              </Can>
              <IonItem
                disabled={downloading}
                onClick={saveAsPdf}
                hidden={segment !== "info"}
              >
                <Icon icon={faDownload} />
                <IonLabel>{t("saveAsPdf")}</IonLabel>
              </IonItem>
              <Can permission={Permission.CaseDelete}>
                <IonItem onClick={onDeleteCaseClicked}>
                  <Icon icon={faTrash} className="dangerColor" />
                  <IonLabel>{t("delete")}</IonLabel>
                </IonItem>
              </Can>
            </IonList>
          </IonPopover>
        </>
      )}
    </IonPage>
  );
};

export default withPermission(Case, Permission.CaseRead);
