import React, { useCallback, useEffect, useState } from "react";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import CaseFormDto, { CaseFlag, CaseStatus } from "../../models/Case";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";
import useTranslation from "../../context/LanguageProvider";
import ButtonTextIcon from "../ButtonTextIcon";
import { useAuthContext } from "../../context/AuthProvider";
import CaseForm from "./CaseForm";
import Icon from "../Icon";
import { faSave, faSpinner } from "@fortawesome/free-solid-svg-icons";
import withPermission from "../../data/withPermission";
import { Permission } from "../../models/Permissions";
import { useHistory } from "react-router";
import useUrlSearchParams from "../../hooks/useUrlSearchParams";

const NewCase: React.FC = () => {
  const { user } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const doctorId = useUrlSearchParams("doctorId");

  const [$case, setCase] = useState<CaseFormDto>({
    id: 0,
    doctorId: doctorId ? parseInt(doctorId) : 0,
    status: CaseStatus.InProgress,
    name: "",
    received: new Date().toISOString(),
    flag: CaseFlag.None,
    products: [],
    teethCondition: [],
    autoName: true
  });

  const { apiPost } = useApi();
  const { handleError, showSuccessToast, showErrorToast } =
    useNotificationContext();
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    if (user?.doctorId)
      setCase(c => ({
        ...c,
        doctorId: user.doctorId ?? 0,
        status: c.id > 0 ? c.status : CaseStatus.PendingAccept
      }));
  }, [user?.doctorId]);

  const upsertCase = useCallback(() => {
    if (!$case.name) {
      showErrorToast(t("cases.noNameError"));
      return;
    }
    if (!$case.doctorId) {
      showErrorToast(t("cases.noDoctorError"));
      return;
    }
    setLoading(true);
    apiPost<number>("case/upsert", $case)
      .then(id => {
        showSuccessToast(t("saved"));
        history.replace(`/case/${id}`);
      })
      .catch(handleError)
      .finally(() => setLoading(false));
  }, [
    $case,
    history,
    apiPost,
    handleError,
    showErrorToast,
    showSuccessToast,
    t
  ]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/" />
          </IonButtons>
          <IonTitle>{t("cases.new")}</IonTitle>
          <IonButtons slot="primary">
            <IonButton disabled={loading} onClick={upsertCase}>
              <ButtonTextIcon button="save" loading={loading} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <CaseForm
          onCaseChange={data => setCase(c => ({ ...c, ...data }))}
          $case={$case}
        />

        <IonButton
          color="secondary"
          expand="block"
          onClick={upsertCase}
          disabled={loading}
          className="ion-margin-top"
        >
          {loading ? <Icon spin icon={faSpinner} /> : <Icon icon={faSave} />}
          {t("save")}
        </IonButton>
      </IonContent>
    </IonPage>
  );
};

export default withPermission(NewCase, Permission.CaseCreate);
