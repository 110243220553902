import { IonAccordion, IonAccordionGroup, IonItem } from "@ionic/react";
import React, { useState } from "react";
import { getCaseColor } from "../../data/calendarColorHelpers";
import { CaseStatus } from "../../models/Case";
import CaseEvent from "./CaseEvent";
import { GroupEvents } from "./useCalendar";

interface Props {
  groupData: GroupEvents;
  onStatusChange: (id: number, status: CaseStatus) => void;
}
const CaseGroupEvent: React.FC<Props> = ({
  groupData: data,
  onStatusChange
}) => {
  const [groupData, setGroupData] = useState({
    ...data,
    events: data.events.filter(d => d.caseEvent)
  });
  const [opened, setOpened] = useState(
    groupData.events.length === 1 ? groupData.time : undefined
  );
  return (
    <>
      <div className="fc-daygrid-event-dot" />
      <IonAccordionGroup
        className="gr_event"
        value={opened}
        onIonChange={e =>
          e.detail.value !== opened && setOpened(e.detail.value)
        }
      >
        <IonAccordion value={groupData.time}>
          <IonItem slot="header" className={groupData.caseColors.join(" ")}>
            {groupData.time + " (" + groupData.events.length + ")"}
          </IonItem>
          <div slot="content">
            {groupData.events.map(ev => (
              <div key={ev.id}>
                <CaseEvent
                  hideTime
                  caseData={{
                    ...ev.caseEvent!,
                    dateType: ev.extendedProps?.dateType ?? ""
                  }}
                  date={(ev.date as string) ?? ""}
                  // onStatusChange={() => {}}
                  onStatusChange={(caseId, newStatus) => {
                    const events = groupData.events
                      .filter(d => d.caseEvent)
                      .map(e =>
                        e.id === ev.id
                          ? {
                              ...e,
                              caseEvent: {
                                ...e.caseEvent!,
                                status: newStatus
                              }
                            }
                          : e
                      );

                    setGroupData({
                      ...groupData,
                      events,
                      caseColors: events.map(
                        e => getCaseColor(e.caseEvent!).bgClass
                      )
                    });
                    // disable for now because the accordion keeps closing of rerender
                    // onStatusChange(caseId, newStatus)
                  }}
                />
              </div>
            ))}
          </div>
        </IonAccordion>
      </IonAccordionGroup>
    </>
  );
};

export default CaseGroupEvent;
