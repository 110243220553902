import React, { useCallback, useEffect, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonCheckbox,
  IonContent,
  IonHeader,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonSelect,
  IonSelectOption,
  IonTextarea,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";
import useTranslation from "../../context/LanguageProvider";
import ButtonTextIcon from "../ButtonTextIcon";
import ModalWrapper from "../ModalWrapper";
import CourierFormDto, {
  CourierNotificationMethod
} from "../../models/Courier";
import Icon from "../Icon";
import { faSave, faSpinner } from "@fortawesome/free-solid-svg-icons";
import withPermission from "../../data/withPermission";
import { Permission } from "../../models/Permissions";

interface Props {
  onSuccess: (id: number) => void;
  onCancel: () => void;
  showModal: boolean;
  initialData: CourierFormDto;
}
const CourierUpsertModal: React.FC<Props> = ({
  onSuccess,
  onCancel,
  showModal,
  initialData
}) => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const { apiPost } = useApi();
  const { t } = useTranslation();

  const { handleError } = useNotificationContext();

  const [data, setData] = useState(initialData);
  const upsert = useCallback(() => {
    setSubmitting(true);
    apiPost<number>("courier/upsert", data)
      .then(onSuccess)
      .catch(handleError)
      .finally(() => setSubmitting(false));
  }, [apiPost, handleError, onSuccess, data]);

  useEffect(() => {
    setSaveDisabled(
      !data.name ||
        (data.notificationMethod === CourierNotificationMethod.Email &&
          (!data.email || !data.emailTemplate)) ||
        (data.notificationMethod === CourierNotificationMethod.Sms &&
          (!data.phone || !data.smsTemplate))
    );
  }, [data]);

  useEffect(() => {
    setData(initialData);
  }, [initialData]);

  return (
    <ModalWrapper modalOpened={showModal} dismiss={onCancel} modal="tuUpsert">
      <IonModal isOpen={showModal} onDidDismiss={onCancel}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={onCancel}>
                <ButtonTextIcon button="cancel" />
              </IonButton>
            </IonButtons>
            <IonTitle>
              {initialData.id > 0 ? t("edit") : t("courier.addCourier")}
            </IonTitle>
            <IonButtons slot="primary">
              <IonButton
                onClick={upsert}
                disabled={isSubmitting || saveDisabled}
              >
                <ButtonTextIcon button="save" loading={isSubmitting} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonItem lines="none">
            <IonLabel position="stacked">{t("name")} *</IonLabel>
            <IonInput
              autocomplete="new-password"
              clearInput
              value={data.name}
              onIonChange={e => setData(d => ({ ...d, name: e.detail.value! }))}
            />
          </IonItem>

          <IonItem className="ion-no-padding ion-margin-top">
            <IonLabel position="stacked">
              {t("courier.notificationMethod")}
            </IonLabel>
            <IonSelect
              interface="action-sheet"
              value={data.notificationMethod}
              onIonChange={e =>
                setData({ ...data, notificationMethod: e.detail.value })
              }
            >
              <IonSelectOption value={CourierNotificationMethod.Sms}>
                {t("sms")}
              </IonSelectOption>
              {/* <IonSelectOption value={CourierNotificationMethod.Email}>
                {t("email")}
              </IonSelectOption> */}
            </IonSelect>
          </IonItem>
          <span
            hidden={data.notificationMethod !== CourierNotificationMethod.Sms}
          >
            <IonItem lines="none">
              <IonLabel position="stacked">{t("phone")} *</IonLabel>
              <IonInput
                autocomplete="new-password"
                clearInput
                value={data.phone}
                onIonChange={e =>
                  setData(d => ({ ...d, phone: e.detail.value! }))
                }
              />
            </IonItem>
            <IonItem className="wion-margin-top">
              <IonLabel position="stacked">
                <p>{t("courier.smsTemplate")} *</p>
              </IonLabel>
              <IonTextarea
                placeholder={t("courier.enterSmsTemplate")}
                value={data.smsTemplate}
                rows={6}
                onIonChange={e =>
                  setData(d => ({ ...d, smsTemplate: e.detail.value! }))
                }
              />
            </IonItem>
          </span>
          <span
            hidden={data.notificationMethod !== CourierNotificationMethod.Email}
          >
            <IonItem lines="none">
              <IonLabel position="stacked">{t("email")} *</IonLabel>
              <IonInput
                autocomplete="new-password"
                clearInput
                value={data.email}
                onIonChange={e =>
                  setData(d => ({ ...d, email: e.detail.value! }))
                }
              />
            </IonItem>
            <IonItem className="wion-margin-top">
              <IonLabel position="stacked">
                <p>{t("courier.emailTemplate")} *</p>
              </IonLabel>
              <IonTextarea
                placeholder={t("courier.enterEmailTemplate")}
                value={data.emailTemplate}
                rows={6}
                onIonChange={e =>
                  setData(d => ({ ...d, emailTemplate: e.detail.value! }))
                }
              />
            </IonItem>
          </span>
          <IonItem hidden={data.id === 0}>
            <IonCheckbox
              slot="start"
              color="primary"
              checked={data.active}
              onIonChange={e =>
                setData(d => ({ ...d, active: e.detail.checked }))
              }
            />
            <IonLabel> {t("courier.enabled")}</IonLabel>
          </IonItem>
          <IonButton
            class="ion-margin-top"
            color="secondary"
            expand="block"
            type="submit"
            disabled={isSubmitting || saveDisabled}
            onClick={upsert}
          >
            {isSubmitting ? (
              <Icon spin icon={faSpinner} />
            ) : (
              <Icon icon={faSave} />
            )}
            {t("save")}
          </IonButton>
        </IonContent>
      </IonModal>
    </ModalWrapper>
  );
};

export default withPermission(CourierUpsertModal, Permission.LabUpdate);
