import React, { useCallback, useEffect, useState } from "react";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonModal,
  IonRadio,
  IonRadioGroup,
  IonTextarea,
  IonTitle,
  IonToolbar
} from "@ionic/react";
import { faEdit, faSpinner } from "@fortawesome/free-solid-svg-icons";
import useApi from "../../data/Api";
import { useNotificationContext } from "../../context/NotificationProvider";
import useTranslation from "../../context/LanguageProvider";
import ButtonTextIcon from "../ButtonTextIcon";
import { ProductionLogFormDto, ProductionLogStatus } from "../../models/Case";
import Icon from "../Icon";
import InfoBox from "../InfoBox";
import ValueLabel from "../ValueLabel";
import useDate from "../../hooks/useDate";
import ProductionLogIcon from "./ProductionLogIcon";
import ModalWrapper from "../ModalWrapper";
import useLab from "../../context/LabProvider";

interface Props {
  onSuccess: (id: number) => void;
  onCancel: () => void;
  showProductionLogModal: boolean;
  initialData: ProductionLogFormDto;
}

const ProductionLogUpdateModal: React.FC<Props> = ({
  onSuccess,
  onCancel,
  initialData,
  showProductionLogModal
}) => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<ProductionLogFormDto>(initialData);
  const [canFinishTask, setCanFinishTask] = useState(false);
  const { tasks } = useLab();
  const { apiPost } = useApi();
  const { t, tProductionLogStatus } = useTranslation();
  const { toDateTimeString } = useDate();
  const { handleError } = useNotificationContext();

  const upsert = useCallback(() => {
    setLoading(true);
    apiPost<number>("productionLog/upsert", formData)
      .then(onSuccess)
      .catch(handleError)
      .finally(() => setLoading(false));
  }, [formData, apiPost, handleError]);

  useEffect(() => {
    setFormData(initialData);
    setCanFinishTask(
      // task that was started or scheduled
      initialData.taskId !== null &&
        (initialData.status === ProductionLogStatus.Started ||
          initialData.status === ProductionLogStatus.Scheduled)
    );
  }, [initialData]);

  return (
    <ModalWrapper
      modalOpened={showProductionLogModal}
      dismiss={onCancel}
      modal="plUpsert"
    >
      <IonModal isOpen={showProductionLogModal} onDidDismiss={onCancel}>
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <IonButton onClick={onCancel}>
                <ButtonTextIcon button="cancel" />
              </IonButton>
            </IonButtons>
            <IonTitle>{t("workLog.update")}</IonTitle>
            <IonButtons slot="primary">
              <IonButton
                onClick={upsert}
                disabled={loading || !formData.caseId}
              >
                <ButtonTextIcon button={"save"} loading={loading} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <div hidden={!initialData.taskId}>
            <ValueLabel title={t("task.title")}>
              {initialData.taskName}
            </ValueLabel>
          </div>
          <div hidden={!initialData.taskId || canFinishTask}>
            <ValueLabel title={t("status")}>
              <ProductionLogIcon
                status={initialData.status}
                taskId={initialData.taskId}
              />{" "}
              {tProductionLogStatus(initialData.status)}
            </ValueLabel>
          </div>
          {initialData.created && initialData.taskId === null && (
            <ValueLabel title={t("date")}>
              {toDateTimeString(initialData.created)}
            </ValueLabel>
          )}
          {initialData.started && (
            <ValueLabel title={t("productionLogStatus.1")}>
              {toDateTimeString(initialData.started)}
            </ValueLabel>
          )}
          {initialData.finished && (
            <ValueLabel title={t("productionLogStatus.2")}>
              {toDateTimeString(initialData.finished)}
            </ValueLabel>
          )}
          <IonRadioGroup
            hidden={!canFinishTask}
            value={formData.status}
            onIonChange={e =>
              setFormData({
                ...formData,
                status: e.detail.value!
              })
            }
          >
            <IonLabel position="stacked">{t("status")}</IonLabel>

            <IonItem>
              <IonLabel> {t("workLog.startedWorkingOnIt")}</IonLabel>
              <IonRadio slot="start" value={ProductionLogStatus.Started} />
            </IonItem>

            <IonItem>
              <IonLabel> {t("workLog.iFinishedTask")}</IonLabel>
              <IonRadio slot="start" value={ProductionLogStatus.Done} />
            </IonItem>

            <IonItem className="ion-margin-top">
              <IonLabel color="danger"> {t("workLog.taskFailed")}</IonLabel>
              <IonRadio slot="start" value={ProductionLogStatus.Failed} />
            </IonItem>
          </IonRadioGroup>

          <IonItem lines="none">
            <IonLabel position="stacked">{t("note")}</IonLabel>
            <IonTextarea
              onIonChange={e =>
                setFormData({
                  ...formData,
                  notes: e.detail.value!
                })
              }
              required={!formData.taskId}
              value={formData.notes}
              placeholder={t("note")}
            />
          </IonItem>
          <IonButton
            onClick={upsert}
            className="ion-margin-top"
            expand="block"
            color="secondary"
            disabled={loading || !formData.caseId}
          >
            {loading ? <Icon spin icon={faSpinner} /> : <Icon icon={faEdit} />}
            {t("save")}
          </IonButton>
          <InfoBox hidden={tasks.length > 0} text={t("task.noDataInfo")} />
        </IonContent>
      </IonModal>
    </ModalWrapper>
  );
};

export default ProductionLogUpdateModal;
