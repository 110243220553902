import React from "react";
import CustomIcon, { CustomIconProps } from "./CustomIcon";

const Icon: React.FC<CustomIconProps> = ({ size = "2em", ...rest }) => (
  <CustomIcon
    size={size}
    enableBackground="new 0 0 1000 1000"
    viewBox="0 0 1000 1000"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g>
      <path d="m684.45 493.55c-22.48 0-36-28.17-45.24-53.06-.05-.13-.1-.26-.14-.39-.55-1.61-13.48-39.9-12.78-94.91a133.45 133.45 0 0 0 -13.48-59.47c-12.59-26-20.29-49.49-22.88-69.77-5.14-40.09 11-60.23 25.67-70.1 5.23-3.87 46.16-32.5 90-16.91l.66.25c31.07 12.59 61.28 12.6 92.35 0l.65-.25c43.89-15.59 84.81 13 90.05 16.91 14.65 9.87 30.81 30 25.67 70.1-2.6 20.28-10.3 43.76-22.88 69.77a133.59 133.59 0 0 0 -13.49 59.47c.71 55-12.22 93.3-12.77 94.91l-.14.39c-9.63 25.8-23.77 55.14-47.75 52.94-22.12-2-31.94-30.94-35.16-40.44a234.39 234.39 0 0 1 -7.94-30.71 5.43 5.43 0 0 1 -.09-.56c-2.07-14.66-5.51-26.91-10.22-36.41a13.58 13.58 0 0 0 -24.22 0c-4.71 9.5-8.14 21.75-10.21 36.41l-.09.56a236.24 236.24 0 0 1 -7.9 30.72c-3.23 9.5-13 38.43-35.16 40.44-.85.07-1.69.11-2.51.11zm-15.16-64c6.87 18.37 12.11 26.5 15 30 4.89-7.23 11.19-25.69 14.21-42.58 2.56-17.93 7-33.35 13.18-45.86a45.6 45.6 0 0 1 81.58 0c6.19 12.5 10.63 27.93 13.18 45.85 3 16.9 9.35 35.36 14.22 42.59 2.89-3.48 8.13-11.61 15-30 .81-2.48 11.6-36.46 11-84a165.69 165.69 0 0 1 16.67-73.82c11.55-23.86 35.43-81.33 7.94-99.53-.31-.2-.61-.42-.91-.65s-30.7-22.81-60.05-12.61c-38.38 15.46-77.29 15.46-115.68 0-29.35-10.21-59.79 12.42-60.09 12.65s-.56.41-.87.61c-27.48 18.21-3.6 75.67 7.94 99.53a165.7 165.7 0 0 1 16.68 73.82c-.61 47.45 10.14 81.38 11 84.02z" />
      <path d="m648.69 875.67h-548.69a16 16 0 0 1 -16-16v-180.8c0-96 78.08-174.06 174.06-174.06h47.83a16 16 0 0 1 13.6 7.58l54.86 88.61 54.85-88.6a16 16 0 0 1 13.6-7.58h47.83c96 0 174.06 78.08 174.06 174.06v180.8a16 16 0 0 1 -16 15.99zm-532.69-32h516.69v-164.8c0-78.33-63.73-142.06-142.06-142.06h-38.91l-63.72 103a16 16 0 0 1 -27.21 0l-63.79-103h-38.94c-78.33 0-142.06 63.73-142.06 142.06z" />
      <path d="m305.91 536.81a16.31 16.31 0 0 1 -2.85-.25 16 16 0 0 1 -12.92-18.56 220.24 220.24 0 0 0 3.34-38.8 225.63 225.63 0 0 0 -2-30.57 16 16 0 1 1 31.7-4.34 257.15 257.15 0 0 1 2.34 34.91 252.65 252.65 0 0 1 -3.84 44.45 16 16 0 0 1 -15.77 13.16z" />
      <path d="m442.79 536.81a16 16 0 0 1 -15.73-13.18 251.77 251.77 0 0 1 -3.85-44.45 258.81 258.81 0 0 1 2.34-34.92 16 16 0 1 1 31.7 4.35 227.14 227.14 0 0 0 -2 30.57 220.32 220.32 0 0 0 3.34 38.8 16 16 0 0 1 -12.92 18.58 16.28 16.28 0 0 1 -2.88.25z" />
      <path d="m537 875.67a16 16 0 0 1 -16-16v-96.87a16 16 0 0 1 32 0v96.87a16 16 0 0 1 -16 16z" />
      <path d="m211.67 875.67a16 16 0 0 1 -16-16v-96.87a16 16 0 0 1 32 0v96.87a16 16 0 0 1 -16 16z" />
      <path d="m211.67 628.9a16 16 0 0 1 -16-16v-85.18a16 16 0 0 1 32 0v85.18a16 16 0 0 1 -16 16z" />
      <path d="m528.27 618.66a16 16 0 0 1 -16-16v-77.34a16 16 0 1 1 32 0v77.34a16 16 0 0 1 -16 16z" />
      <path d="m579 720.17h-27.26a16 16 0 0 1 0-32h11.26v-34.76a34.76 34.76 0 1 0 -69.52 0v34.76h11.32a16 16 0 0 1 0 32h-27.29a16 16 0 0 1 -16-16v-50.76a66.76 66.76 0 1 1 133.52 0v50.76a16 16 0 0 1 -16.03 16z" />
      <path d="m211.67 709.93a56.52 56.52 0 1 1 56.52-56.52 56.58 56.58 0 0 1 -56.52 56.52zm0-81a24.52 24.52 0 1 0 24.52 24.51 24.55 24.55 0 0 0 -24.52-24.54z" />
      <path d="m372.68 482.48a133.64 133.64 0 0 1 -131.68-110.8 16.34 16.34 0 0 1 -.23-2.71 16 16 0 0 1 31.82-2.41 101.62 101.62 0 0 0 199.5 3.44 16 16 0 1 1 31.32 6.57 133.66 133.66 0 0 1 -130.73 106z" />
      <path d="m487.41 389.4a15.77 15.77 0 0 1 -4.12-.55 16 16 0 0 1 -11.59-12.44l-15.24-79.41c-12.93-2.87-31.21-11.86-45.55-38.34q-2.22 2.16-4.83 4.31c-21.57 17.73-59.81 31.73-116.72 42.72l-16.31 67.78a16 16 0 0 1 -27.11 7.32c-2.22-2.32-54.06-57.92-25.95-155.95 1.71-6 12-33.8 27.65-50.89 21.56-23.55 61.16-51.25 127.54-49.36 6.55.18 64.91 2.47 97.11 25.73 28.93 20.9 49 54 59.59 98.46 2.24 9.38 9 42.17 2.06 70.37-5.66 23-17.23 44.72-34.4 64.66a16 16 0 0 1 -12.13 5.59zm-16.7-123.4a15.29 15.29 0 0 1 8.41 2.65c3.67 2.37 5.67 6.24 6.49 10.53l10.1 52.62a111.77 111.77 0 0 0 7.16-20.24c4.15-16.87 1.46-40.32-2.11-55.27-8.78-36.77-24.67-63.67-47.21-80-20.62-14.9-64.21-19.26-79.27-19.69-54.27-1.53-86 20.36-103 39-10.46 11.43-19.11 33.27-20.5 38.11-9.6 33.48-9.45 64.66.44 92.67.06.16.12.32.17.48l9.31-38.69a16 16 0 0 1 12.73-12c70.13-12.61 99.81-27.55 112.36-37.86 11.18-9.18 10.68-15.66 10.24-21.37v-.51a16 16 0 0 1 3.63-11.47l.07-.09a16 16 0 0 1 28 7.11c12.73 53.8 36.86 54.5 39.83 54.37a14.72 14.72 0 0 1 3.15-.35z" />
    </g>
  </CustomIcon>
);

export default Icon;
