import React, { useCallback, useRef, useState } from "react";
import {
  IonContent,
  IonPage,
  IonInput,
  IonButton,
  IonRow,
  IonCol,
  IonText
} from "@ionic/react";
import { useAuthContext } from "../../context/AuthProvider";
import { useNotificationContext } from "../../context/NotificationProvider";
import useTranslation from "../../context/LanguageProvider";
import { useHistory } from "react-router";
import Icon from "../Icon";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import SimpleReactValidator from "simple-react-validator";
import GoogleLoginButton from "./GoogleLoginButton";
import AuthLayout from "./AuthLayout";
import useLocalStorage from "../../hooks/useLocalStorage";

interface UserPass {
  email: string;
  password: string;
}

const simpleValidator = new SimpleReactValidator();

const Login: React.FC = () => {
  const { login } = useAuthContext();
  const [credentials, setCredentials] = useState<UserPass>({
    email: "",
    password: ""
  });
  const { handleError } = useNotificationContext();
  const { t, tPlaceholder } = useTranslation();
  const history = useHistory();
  const [invitationCode] = useLocalStorage("invitationCode", "");

  const [, forceUpdateValidator] = useState(false);
  const validatorRef = useRef(simpleValidator);

  const loginLocal = useCallback(() => {
    validatorRef.current.purgeFields();
    if (!validatorRef.current.allValid()) {
      console.error(
        "error",
        validatorRef.current.getErrorMessages(),
        validatorRef.current
      );
      validatorRef.current.showMessages();
      return forceUpdateValidator(v => !v);
    }
    login(credentials.email, credentials.password)
      .then(() => invitationCode && history.push("/invitation"))
      .catch(handleError);
  }, [credentials, history, invitationCode, login, handleError]);

  return (
    <IonPage>
      <IonContent className="unauthed-page">
        <AuthLayout>
          <h3 className="ion-margin-bottom">{t("login")}</h3>
          <div>
            <IonInput
              placeholder={tPlaceholder("email")}
              type="email"
              inputmode="email"
              value={credentials.email}
              autocomplete="honorific-suffix"
              id="email123"
              onIonChange={e =>
                setCredentials(c => ({
                  ...c,
                  email: e.detail.value!
                }))
              }
            />
            {validatorRef.current.message(
              t("email"),
              credentials.email,
              "required|email",
              { className: "dangerColor" }
            )}
          </div>
          <div>
            <IonInput
              autocomplete="new-password"
              id="new-password"
              value={credentials.password}
              placeholder={tPlaceholder("password")}
              clearInput
              type="password"
              onKeyUp={e => {
                if (
                  e.code === "Enter" &&
                  credentials.password.length &&
                  credentials.email.length
                )
                  loginLocal();
              }}
              onIonChange={e =>
                setCredentials(c => ({
                  ...c,
                  password: e.detail.value!
                }))
              }
            />
            {validatorRef.current.message(
              "password",
              credentials.password,
              "required",
              { className: "dangerColor" }
            )}
          </div>
          <IonButton
            class="ion-margin-vertical"
            color="primary"
            expand="block"
            type="submit"
            onClick={loginLocal}
          >
            <Icon icon={faSignInAlt} slot="start" />
            {t("login")}
          </IonButton>
          <div className="ion-text-center">
            <IonText className="greyColor">{t("orLoginWith")}</IonText>
          </div>

          <IonRow>
            <IonCol className="ion-no-padding">
              <GoogleLoginButton />
            </IonCol>
          </IonRow>
          <IonButton
            className="ion-margin-top"
            routerLink="forgot-password"
            fill="clear"
            // size="small"
            expand="block"
          >
            {t("forgotYourPassword")}
          </IonButton>

          <IonButton routerLink="register" fill="clear" expand="block">
            {t("dontHaveAccount")}
          </IonButton>
        </AuthLayout>
      </IonContent>
    </IonPage>
  );
};

export default Login;
