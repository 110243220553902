import React from "react";
import { IonSegmentButton, IonLabel } from "@ionic/react";
import VersatileIcon, { IconKind } from "./VersatileIcon";

interface Props {
  value: string;
  title: string;
  icon: IconKind;
  count?: number;
  iconClass?: string;
}

const SegmentButtonTextIcon: React.FC<Props> = ({
  value,
  title,
  icon,
  count,
  iconClass
}) => (
  <IonSegmentButton value={value} layout="icon-start">
    <VersatileIcon icon={icon} className={`segment-button-icon ${iconClass}`} />
    <IonLabel>
      <IonLabel className="ion-hide-sm-down">{title}</IonLabel>
      {count !== undefined && count > 0 && (
        <span className="content-font"> ({count}) </span>
      )}
    </IonLabel>
  </IonSegmentButton>
);

export default SegmentButtonTextIcon;
