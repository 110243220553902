import React from "react";

export interface CustomIconProps extends Omit<Props, "children"> {}

interface Props extends React.SVGAttributes<SVGSVGElement> {
  size?: string | number;
  // All other props
  [x: string]: any;
}

const CustomIcon: React.FC<Props> = ({ size, children, ...rest }) => (
  <svg height={size} width={size} {...rest}>
    {children}
  </svg>
);

export default CustomIcon;
