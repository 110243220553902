import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonMenuButton,
  IonButton
} from "@ionic/react";
import React, { useState } from "react";

import withPermission from "../../data/withPermission";
import { Permission } from "../../models/Permissions";

import useTranslation from "../../context/LanguageProvider";
import Icon from "../Icon";
import {
  faChevronLeft,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";
import Calendar from "./Calendar";
import useLocalStorage from "../../hooks/useLocalStorage";
import { CalendarView } from "./useCalendar";
import { Switch } from "antd";

const CalendarPage: React.FC = () => {
  const [view, setView] = useLocalStorage<CalendarView>(
    "calendarView",
    "dayGridMonth"
  );
  const [title, setTitle] = useState<string>();
  const [next, setNext] = useState("");
  const [prev, setPrev] = useState("");
  const { t } = useTranslation();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle className="ion-text-capitalize">
            {title ?? t("calendar.title")}
          </IonTitle>
          <IonButtons slot="end">
            <Switch
              checkedChildren={t("calendar.week")}
              unCheckedChildren={t("calendar.month")}
              onChange={value =>
                setView(value ? "timeGridWeek" : "dayGridMonth")
              }
              checked={view === "timeGridWeek"}
            />
            <IonButton onClick={() => setPrev(new Date().toString())}>
              <Icon icon={faChevronLeft} />
            </IonButton>
            <IonButton onClick={() => setNext(new Date().toString())}>
              <Icon icon={faChevronRight} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <Calendar
          onTitleChange={setTitle}
          nextClicked={next}
          prevClicked={prev}
          view={view}
        />
      </IonContent>
    </IonPage>
  );
};

export default withPermission(CalendarPage, Permission.CaseRead);
