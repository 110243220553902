import { faFile } from "@fortawesome/free-regular-svg-icons";
import {
  faDownload,
  faSpinner,
  faUpload
} from "@fortawesome/free-solid-svg-icons";
import {
  IonAvatar,
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  IonRow,
  IonSkeletonText
} from "@ionic/react";
import { closeCircle } from "ionicons/icons";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import { useNotificationContext } from "../../context/NotificationProvider";
import useApi from "../../data/Api";
import useTranslation from "../../context/LanguageProvider";
import useDate from "../../hooks/useDate";
import { CaseAttachmentWithFileDto, CaseViewDto } from "../../models/Case";
import Icon from "../Icon";
import ImagePreviewModal from "./ImagePreviewModal";
import { useAuthContext } from "../../context/AuthProvider";

interface ShowImageProps {
  fileName: string;
  imageUrl: string;
}

interface Props {
  $case: CaseViewDto;
}

const CaseAttachments: React.FC<Props> = ({ $case }) => {
  const [attachments, setAttachments] = useState<CaseAttachmentWithFileDto[]>();
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [downloadingFile, setDownloadingFile] = useState<string>();
  const [showImage, setShowImage] = useState<ShowImageProps>();
  const { user } = useAuthContext();
  const { t } = useTranslation();
  const { apiGet, apiGetImage, apiBlobDownload, apiPutMultiForm, apiPost } =
    useApi();
  const { toDateString } = useDate();
  const { handleError, showSuccessToast } = useNotificationContext();
  const uploadRef = useRef<HTMLInputElement>(null);

  const fetchAttachments = useCallback(() => {
    setLoading(true);
    return apiGet<CaseAttachmentWithFileDto[]>(
      `case/getAttachments?id=${$case.id}`
    )
      .then(setAttachments)
      .catch(handleError)
      .finally(() => setLoading(false));
  }, [apiGet, $case.id, handleError]);

  useEffect(() => {
    fetchAttachments();
  }, [fetchAttachments]);

  const onUploadClick = useCallback(() => {
    uploadRef?.current?.click();
  }, [uploadRef]);

  const upload = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        const formData = new FormData();
        formData.append("id", $case.id.toString());
        Array.from(event.target.files).forEach(file =>
          formData.append("files", file, file.name)
        );

        setUploading(true);
        apiPutMultiForm("case/UploadFiles", formData)
          .then(() => {
            fetchAttachments();
            showSuccessToast(t("cases.filesUploaded"));
          })
          .catch(handleError)
          .finally(() => setUploading(false));
      }
    },
    [
      $case.id,
      apiPutMultiForm,
      fetchAttachments,
      handleError,
      showSuccessToast,
      t
    ]
  );

  const deleteFile = useCallback(
    (name: string) => {
      apiPost("case/DeleteFiles", {
        id: $case.id,
        files: [name]
      })
        .then(() => fetchAttachments())
        .catch(handleError);
    },
    [$case.id, apiPost, handleError, fetchAttachments]
  );

  const openFile = useCallback(
    (fileName: string, id: number) => {
      const path = `case/GetAttachment?caseId=${$case.id}&fileId=${id}`;
      setDownloadingFile(fileName);
      apiGetImage(path)
        .then(file => {
          setShowImage(
            file ? { imageUrl: URL.createObjectURL(file), fileName } : undefined
          );
        })
        .catch(handleError)
        .finally(() => setDownloadingFile(undefined));
    },
    [$case.id, apiGetImage, handleError]
  );
  const downloadFile = useCallback(
    (fileName: string, id: number) => {
      const path = `case/GetAttachment?caseId=${$case.id}&fileId=${id}`;
      setDownloadingFile(fileName);
      apiBlobDownload("GET", path, undefined)
        .catch(handleError)
        .finally(() => setDownloadingFile(undefined));
    },
    [$case.id, apiBlobDownload, handleError]
  );

  return (
    <>
      <IonGrid>
        <IonRow>
          {attachments && !loading ? (
            <IonCol>
              <h3>{t("cases.attachments")}</h3>
              <IonList hidden={!attachments.length}>
                {attachments.map(
                  ({ caseAttachment, fileType, file: thumbnail }) => (
                    <IonItem key={caseAttachment.id}>
                      {downloadingFile === caseAttachment.fileName ? (
                        <Icon icon={faSpinner} slot="start" size="2x" spin />
                      ) : thumbnail ? (
                        <IonAvatar
                          key={caseAttachment.id}
                          slot="start"
                          className="pointer"
                          onClick={() =>
                            openFile(caseAttachment.fileName, caseAttachment.id)
                          }
                        >
                          <IonImg
                            src={`data:${fileType};base64,${thumbnail}`}
                          />
                        </IonAvatar>
                      ) : (
                        <IonAvatar
                          key={caseAttachment.id}
                          slot="start"
                          className="pointer"
                          onClick={() =>
                            downloadFile(
                              caseAttachment.fileName,
                              caseAttachment.id
                            )
                          }
                        >
                          <Icon style={{ fontSize: 50 }} icon={faFile} />
                        </IonAvatar>
                      )}

                      <IonLabel
                        className="pointer"
                        onClick={() =>
                          thumbnail
                            ? openFile(
                                caseAttachment.fileName,
                                caseAttachment.id
                              )
                            : downloadFile(
                                caseAttachment.fileName,
                                caseAttachment.id
                              )
                        }
                      >
                        {caseAttachment.fileName}
                        <p className="smaller-font">
                          ({caseAttachment.uploadedByName})
                        </p>
                        {caseAttachment.created && (
                          <p slot="end">
                            {toDateString(caseAttachment.created)}
                          </p>
                        )}
                      </IonLabel>

                      <Icon
                        icon={faDownload}
                        slot="end"
                        size="2x"
                        className="pointer"
                        onClick={() =>
                          downloadFile(
                            caseAttachment.fileName,
                            caseAttachment.id
                          )
                        }
                      />
                      {caseAttachment.uploadedByEmployee === user?.userId && (
                        <IonIcon
                          icon={closeCircle}
                          title="delete attachment"
                          className="pointer"
                          onClick={() => deleteFile(caseAttachment.fileName)}
                          slot="end"
                          color="danger"
                        />
                      )}
                    </IonItem>
                  )
                )}
              </IonList>
              <input
                ref={uploadRef}
                type="file"
                multiple
                accept="image/png,image/jpeg,image/jpg,application/pdf"
                onChange={upload}
                hidden
              />

              <div className="ion-text-center ion-margin-top">
                <IonButton
                  color="secondary"
                  onClick={onUploadClick}
                  disabled={uploading}
                >
                  <Icon
                    icon={uploading ? faSpinner : faUpload}
                    spin={uploading}
                  />{" "}
                  {t("upload")}
                </IonButton>
              </div>
            </IonCol>
          ) : (
            <IonSkeletonText animated />
          )}
        </IonRow>
      </IonGrid>
      <ImagePreviewModal
        showModal={showImage !== undefined}
        imageUrl={showImage?.imageUrl ?? ""}
        fileName={showImage?.fileName ?? ""}
        onCancel={() => setShowImage(undefined)}
      />
    </>
  );
};

export default CaseAttachments;
