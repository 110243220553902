import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonContent,
  IonButton,
  IonMenuButton,
  IonGrid,
  IonSegment,
  IonCol,
  IonRow
} from "@ionic/react";
import React, { useState } from "react";
import useTranslation from "../../context/LanguageProvider";
import withPermission from "../../data/withPermission";
import { Permission } from "../../models/Permissions";
import { StatsProps } from "../../models/UrlProps";
import ButtonTextIcon from "../ButtonTextIcon";
import ItemLabel from "../ItemLabel";
import SelectDoctor from "../doctor/SelectDoctor";
import { Select } from "antd";
import PopupDateRangePicker from "../PopupDateRangePicker";
import CasesStats from "./CasesStats";
import InvoicesStats from "./InvoicesStats";
// import ProductionLogsStats from "./ProductionLogsStats";
import { useAuthContext } from "../../context/AuthProvider";
import useLab from "../../context/LabProvider";
import SegmentButtonTextIcon from "../SegmentButtonTextIcon";
import {
  faMoneyBillWave,
  faPuzzlePiece,
  faTasks,
  faTeethOpen
} from "@fortawesome/free-solid-svg-icons";
import MaterialsStats from "./MaterialsStats";
import CasesIcon from "../icons/CasesIcon";
import InvoicesIcon from "../icons/InvoicesIcon";
import { SlPuzzle } from "react-icons/sl";

const { Option } = Select;

const Stats: React.FC = () => {
  const { user } = useAuthContext();
  const { employees, materials } = useLab();
  const [showFilter, setShowFilter] = useState(true);
  const [urlProps, setUrlProps] = useState<StatsProps>({
    page: 1,
    pageSize: 10,
    employeeId: null,
    doctorId: user?.doctorId !== undefined ? user?.doctorId : undefined,
    materialId: null
  });
  const [view, setView] = useState<
    "cases" | "invoices" | "materials" // | "productionLogs"
  >("cases");
  const { t } = useTranslation();

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{t("stats.title")}</IonTitle>
          <IonButtons slot="primary">
            <IonButton
              color={showFilter ? "primary" : ""}
              onClick={() => setShowFilter(!showFilter)}
            >
              <ButtonTextIcon
                button="filter"
                iconClass={showFilter ? "primaryColor" : ""}
              />
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar className="header_toolbar2">
          <IonSegment
            value={view}
            onIonChange={e => setView(e.detail.value as any)}
          >
            <SegmentButtonTextIcon
              value="cases"
              title={t("cases.title")}
              icon={CasesIcon}
            />
            <SegmentButtonTextIcon
              value="invoices"
              title={t("invoices.title")}
              icon={InvoicesIcon}
            />
            {user!.doctorId === undefined && (
              <>
                {/* <SegmentButtonTextIcon
                  value="productionLogs"
                  title={t("workLog.title")}
                  icon={faTasks}
                /> */}
                <SegmentButtonTextIcon
                  value="materials"
                  title={t("materials.title")}
                  icon={SlPuzzle}
                />
              </>
            )}
          </IonSegment>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonGrid>
          <IonRow hidden={!showFilter}>
            {user!.doctorId === undefined && (
              <IonCol size-xs="6" size-xl="3">
                <SelectDoctor
                  onChange={id =>
                    setUrlProps(urlProps => ({
                      ...urlProps,
                      doctorId: id
                    }))
                  }
                />
              </IonCol>
            )}
            <IonCol hidden>
              <ItemLabel label={t("employee")}>
                <Select
                  size="large"
                  // mode="multiple"
                  placeholder={t("employee")}
                  defaultValue={
                    urlProps.employeeId !== null ? urlProps.employeeId : ""
                  }
                  onChange={e => {
                    setUrlProps(urlProps => ({
                      ...urlProps,
                      employeeId: e === "" ? null : (e as number)
                    }));
                  }}
                >
                  <Option value="">{t("all")}</Option>
                  {employees?.map(e => (
                    <Option key={e.id} value={e.id}>
                      {`${e.firstName} ${e.lastName}`}
                    </Option>
                  ))}
                </Select>
              </ItemLabel>
            </IonCol>
            <IonCol hidden={view !== "materials"}>
              <ItemLabel label={t("materials.title")}>
                <Select
                  placeholder={t("materials.selectMaterial")}
                  size="large"
                  defaultValue={
                    urlProps.materialId !== null ? urlProps.materialId : ""
                  }
                  onChange={e => {
                    setUrlProps(urlProps => ({
                      ...urlProps,
                      materialId: e === "" ? null : (e as number)
                    }));
                  }}
                >
                  <Option value="">{t("all")}</Option>
                  {materials.map(e => (
                    <Option key={e.id} value={e.id}>
                      {e.name}
                    </Option>
                  ))}
                </Select>
              </ItemLabel>
            </IonCol>
            <IonCol>
              <ItemLabel label={t("dates.chooseDate")}>
                <PopupDateRangePicker
                  onChange={({ start, end }) =>
                    setUrlProps(urlProps => ({
                      ...urlProps,
                      fromDate: start,
                      toDate: end
                    }))
                  }
                  start={urlProps.fromDate}
                  end={urlProps.toDate}
                />
              </ItemLabel>
            </IonCol>
          </IonRow>

          <CasesStats {...urlProps} status={null} hidden={view !== "cases"} />

          <InvoicesStats
            {...urlProps}
            status={null}
            hidden={view !== "invoices"}
          />

          {/* <ProductionLogsStats
            {...urlProps}
            status={null}
            taskId={null}
            hidden={view !== "productionLogs"}
          /> */}

          <MaterialsStats {...urlProps} hidden={view !== "materials"} />
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default withPermission(Stats, Permission.StatsRead);
