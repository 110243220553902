const config = {
  apiKey: "AIzaSyADv5ojwQ9u3h1JZmDRC-HMogjKlBwDn4U",
  authDomain: "organizator-zubne-tehnike.firebaseapp.com",
  projectId: "organizator-zubne-tehnike",
  storageBucket: "organizator-zubne-tehnike.appspot.com",
  messagingSenderId: "63626973782",
  appId: "1:63626973782:web:ad10dbc37169686982e1f7",
  measurementId: "G-RPKDJXKPFD"
};

export const VAPID_KEY =
  "BLP99YBnrGVR-2FE3wh2SuGzyHL89SxDNn_beEkzNwxbmQm_gcJ21_qZUW";

export default config;
