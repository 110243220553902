import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  useIonViewWillEnter,
  IonLabel,
  IonItem,
  IonMenuButton,
  IonListHeader
} from "@ionic/react";
import React, { useState } from "react";
import { useAuthContext } from "../context/AuthProvider";
import { useNotificationContext } from "../context/NotificationProvider";
import useApi from "../data/Api";
import LabDto from "../models/Lab";
import { Permission } from "../models/Permissions";
import Can from "./Can";
import useTranslation from "../context/LanguageProvider";
import LanguageSelect from "./LanguageSelect";
import { AiOutlineIdcard } from "react-icons/ai";
import { MdOutlineBadge, MdOutlineNotificationsActive } from "react-icons/md";
import { FaRegEdit } from "react-icons/fa";
import { SlPuzzle, SlPaperClip } from "react-icons/sl";
import { CgArrowsExchange } from "react-icons/cg";
import ProductsIcon from "./icons/ProductsIcon";
import VersatileIcon, { IconKind } from "./VersatileIcon";
import usePushNotifications from "../data/pushNotifications";

interface MenuButtonProps {
  url: string;
  icon: IconKind;
  title: string;
}

const MenuButton: React.FC<MenuButtonProps> = ({ icon, title, url }) => (
  <IonItem button detail routerLink={url} className="settings-menu">
    <VersatileIcon icon={icon} />
    <IonLabel>{title}</IonLabel>
  </IonItem>
);

const Settings: React.FC = () => {
  const { labId, user } = useAuthContext();
  const { apiGet } = useApi();
  const { handleError } = useNotificationContext();
  const [labs, setLabs] = useState<LabDto[]>([]);
  const { t } = useTranslation();

  const { notificationsSupported } = usePushNotifications();

  useIonViewWillEnter(() => {
    apiGet<LabDto[]>("lab/getall").then(setLabs).catch(handleError);
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>{t("settings")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding settings-menu">
        {labId && user?.doctorId === undefined && (
          <>
            <IonListHeader>{t("settings.titles.myLab")}</IonListHeader>
            {/* <Can permission={Permission.TaskRead}>
              <MenuButton
                icon={faNetworkWired}
                title={t("task.titlePlural")}
                url="/tasks"
              />
            </Can>
            <MenuButton
              icon={faNetworkWired}
              title={t("schedulingTemplate.titlePlural")}
              url="/scheduling-templates"
            /> */}

            <Can permission={Permission.ProductsRead}>
              <MenuButton
                icon={ProductsIcon}
                title={t("products.title")}
                url="/products"
              />
              <MenuButton
                icon={SlPaperClip}
                title={t("extras.title")}
                url="/extras"
              />
              <MenuButton
                icon={SlPuzzle}
                title={t("materials.title")}
                url="/materials"
              />
            </Can>
            <Can permission={Permission.EmployeeRead}>
              <MenuButton
                icon={MdOutlineBadge}
                title={t("employees.title")}
                url="/employees"
              />
            </Can>
            <Can permission={Permission.LabUpdate}>
              <MenuButton
                icon={FaRegEdit}
                title={t("editLab.title")}
                url="/my-lab"
              />
            </Can>
            {/* <div>
              <ShowForLabs labs={[20, 12, 14]}>
                <Can permission={Permission.LabUpdate}>
                  <MenuButton
                    icon={faTruckMoving}
                    title={t("courier.settingsTitle")}
                    url="/couriers"
                  />
                </Can>
              </ShowForLabs>
            </div> */}
          </>
        )}

        <IonListHeader>{t("settings.titles.myProfile")}</IonListHeader>
        <MenuButton
          icon={AiOutlineIdcard}
          title={t("profile.editProfile")}
          url="/profile"
        />
        {notificationsSupported && (
          <MenuButton
            icon={MdOutlineNotificationsActive}
            title={t("notifications.title")}
            url="/notifications"
          />
        )}

        {labs.length > 1 && (
          <MenuButton
            icon={CgArrowsExchange}
            title={t("changeLab.title")}
            url="/change-lab"
          />
        )}
        <IonListHeader>{t("selectLanguage")}</IonListHeader>

        <div className="ion-margin-horizontal">
          <LanguageSelect />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Settings;
