import { faSave } from "@fortawesome/free-regular-svg-icons";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import {
  IonContent,
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonTitle,
  IonBackButton,
  IonCol,
  IonGrid,
  IonRow,
  IonItem,
  IonLabel,
  IonToggle,
  IonButton,
  useIonViewWillEnter,
  IonSkeletonText
} from "@ionic/react";
import React, { useCallback, useState } from "react";
import { useNotificationContext } from "../../context/NotificationProvider";
import useApi from "../../data/Api";
import usePushNotifications from "../../data/pushNotifications";
import useTranslation from "../../context/LanguageProvider";
import { Permission } from "../../models/Permissions";
import Can from "../Can";
import Icon from "../Icon";
import WarningBox from "../WarningBox";
import { useAuthContext } from "../../context/AuthProvider";

interface NotificationPermissionsDto {
  taskAdded: boolean;
  missingColor: boolean;
  newCase: boolean;
  caseStatusChanged: boolean;
  caseDeadlineMissed: boolean;
  caseDeadlineAproaching: boolean;
  taskDeadlineMissed: boolean;
  taskDeadlineAproaching: boolean;
}

const Notifications: React.FC = () => {
  const [data, setData] = useState<NotificationPermissionsDto>({
    taskAdded: true,
    missingColor: true,
    newCase: true,
    caseStatusChanged: true,
    caseDeadlineMissed: true,
    caseDeadlineAproaching: true,
    taskDeadlineMissed: true,
    taskDeadlineAproaching: true
  });
  const { t } = useTranslation();
  const {
    checkToken,
    loading: checkingToken,
    userConsent,
    notificationsSupported
  } = usePushNotifications();
  const { user } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const { apiGet, apiPost } = useApi();
  const { handleError, showSuccessToast } = useNotificationContext();

  useIonViewWillEnter(() => {
    setLoading(true);
    apiGet<NotificationPermissionsDto>("settings/getPermissions")
      .then(setData)
      .catch(handleError)
      .finally(() => setLoading(false));
  });

  const updateNotifications = useCallback(() => {
    setSaving(true);
    return apiPost("settings/setPermissions", data)
      .then(() => showSuccessToast(t("profile.updated")))
      .catch(handleError)
      .finally(() => setSaving(false));
  }, [data, apiPost, handleError, showSuccessToast, t]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/settings" />
          </IonButtons>
          <IonTitle>{t("notifications.editNotifications")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        {loading ? (
          <IonSkeletonText animated title={t("loading")} />
        ) : (
          <IonGrid>
            <IonRow>
              {notificationsSupported ? (
                <IonCol
                  size-sm="10"
                  offset-sm="1"
                  size-md="6"
                  offset-md="3"
                  size-xl="4"
                  offset-xl="4"
                >
                  <IonButton
                    class="ion-margin"
                    color="secondary"
                    expand="block"
                    hidden={
                      userConsent === "granted" || userConsent === "denied"
                    }
                    disabled={checkingToken}
                    onClick={checkToken}
                  >
                    {checkingToken && <Icon icon={faSpinner} spin />}
                    {t("notifications.allowNotifications")}
                  </IonButton>
                  <WarningBox
                    hidden={userConsent !== "denied"}
                    text={t("notifications.notificationsDeniedText")}
                  />

                  <div hidden={userConsent !== "granted"}>
                    <IonItem>
                      <p>{t("notifications.when")}</p>
                    </IonItem>

                    {!user?.doctorId && (
                      <>
                        <Can permission={Permission.TaskUpdate}>
                          <IonItem>
                            <IonLabel className="ion-text-wrap">
                              {t("notifications.taskAdded")}
                            </IonLabel>
                            <IonToggle
                              slot="start"
                              checked={data.taskAdded}
                              // disabled={!notificationsGranted}
                              onIonChange={e =>
                                setData({
                                  ...data,
                                  taskAdded: e.detail.checked
                                })
                              }
                            />
                          </IonItem>
                        </Can>

                        <Can permission={Permission.ProductionLogUpdate}>
                          <IonItem>
                            <IonLabel className="ion-text-wrap">
                              {t("notifications.taskDeadlineMissed")}
                            </IonLabel>
                            <IonToggle
                              slot="start"
                              checked={data.taskDeadlineMissed}
                              // disabled={!notificationsGranted}
                              onIonChange={e =>
                                setData({
                                  ...data,
                                  taskDeadlineMissed: e.detail.checked
                                })
                              }
                            />
                          </IonItem>
                        </Can>

                        <Can permission={Permission.ProductionLogUpdate}>
                          <IonItem>
                            <IonLabel className="ion-text-wrap">
                              {t("notifications.taskDeadlineAproaching")}
                            </IonLabel>
                            <IonToggle
                              slot="start"
                              checked={data.taskDeadlineAproaching}
                              // disabled={!notificationsGranted}
                              onIonChange={e =>
                                setData({
                                  ...data,
                                  taskDeadlineAproaching: e.detail.checked
                                })
                              }
                            />
                          </IonItem>
                        </Can>

                        <Can permission={Permission.CaseUpdate}>
                          <IonItem>
                            <IonLabel className="ion-text-wrap">
                              {t("notifications.missingColor")}
                            </IonLabel>
                            <IonToggle
                              slot="start"
                              checked={data.missingColor}
                              // disabled={!notificationsGranted}
                              onIonChange={e =>
                                setData({
                                  ...data,
                                  missingColor: e.detail.checked
                                })
                              }
                            />
                          </IonItem>
                        </Can>
                      </>
                    )}
                    <Can permission={Permission.CaseCreate}>
                      <IonItem>
                        <IonLabel className="ion-text-wrap">
                          {t("notifications.newCase")}
                        </IonLabel>
                        <IonToggle
                          slot="start"
                          checked={data.newCase}
                          // disabled={!notificationsGranted}
                          onIonChange={e =>
                            setData({
                              ...data,
                              newCase: e.detail.checked
                            })
                          }
                        />
                      </IonItem>
                    </Can>

                    <Can permission={Permission.CaseRead}>
                      <IonItem>
                        <IonLabel className="ion-text-wrap">
                          {t("notifications.caseStatusChanged")}
                        </IonLabel>
                        <IonToggle
                          slot="start"
                          checked={data.caseStatusChanged}
                          // disabled={!notificationsGranted}
                          onIonChange={e =>
                            setData({
                              ...data,
                              caseStatusChanged: e.detail.checked
                            })
                          }
                        />
                      </IonItem>
                    </Can>

                    <Can permission={Permission.CaseUpdate}>
                      <IonItem>
                        <IonLabel className="ion-text-wrap">
                          {t("notifications.caseDeadlineMissed")}
                        </IonLabel>
                        <IonToggle
                          slot="start"
                          checked={data.caseDeadlineMissed}
                          // disabled={!notificationsGranted}
                          onIonChange={e =>
                            setData({
                              ...data,
                              caseDeadlineMissed: e.detail.checked
                            })
                          }
                        />
                      </IonItem>
                    </Can>

                    <Can permission={Permission.CaseUpdate}>
                      <IonItem>
                        <IonLabel className="ion-text-wrap">
                          {t("notifications.caseDeadlineAproaching")}
                        </IonLabel>
                        <IonToggle
                          slot="start"
                          checked={data.caseDeadlineAproaching}
                          // disabled={!notificationsGranted}
                          onIonChange={e =>
                            setData({
                              ...data,
                              caseDeadlineAproaching: e.detail.checked
                            })
                          }
                        />
                      </IonItem>
                    </Can>

                    <IonButton
                      class="ion-margin"
                      color="secondary"
                      expand="block"
                      // disabled={!notificationsGranted || saving}
                      onClick={updateNotifications}
                    >
                      {saving ? (
                        <Icon spin icon={faSpinner} />
                      ) : (
                        <Icon icon={faSave} />
                      )}
                      {t("save")}
                    </IonButton>
                  </div>
                </IonCol>
              ) : (
                <IonCol>
                  <IonTitle>{t("notifications.notEnabled")}</IonTitle>
                </IonCol>
              )}
            </IonRow>
          </IonGrid>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Notifications;
