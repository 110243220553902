import React from "react";
import useTranslation, {
  Language,
  Languages
} from "../context/LanguageProvider";
import { Select } from "antd";

const { Option } = Select;

interface Props {
  onChange?: (lng: Language) => void;
  darkBg?: boolean;
}

const LanguageSelect: React.FC<Props> = ({ onChange, darkBg }) => {
  const { t, language, changeLanguage } = useTranslation();
  return (
    <Select
      placeholder={t("selectLanguage")}
      size="large"
      className={darkBg ? "whiteColor" : ""}
      value={language}
      onChange={v => {
        changeLanguage(v);
        onChange && onChange(v);
      }}
      placement="topLeft"
    >
      {Languages.map(o => (
        <Option value={o.value} key={o.value} title={o.name}>
          <img src={o.flag} alt="" className="flag" />
          {o.name}
        </Option>
      ))}
    </Select>
  );
};

export default LanguageSelect;
