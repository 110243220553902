import {
  IonPage,
  IonToolbar,
  IonList,
  IonItem,
  IonLabel,
  IonButton,
  IonNote,
  IonSegment,
  IonSegmentButton,
  useIonViewWillEnter,
  IonListHeader,
  IonSkeletonText
} from "@ionic/react";
import React, { useCallback, useEffect, useState } from "react";
import Employee from "../../models/Employee";
import useApi from "../../data/Api";

import { useNotificationContext } from "../../context/NotificationProvider";
import Icon from "../../components/Icon";
import { faIdBadge, faPlus } from "@fortawesome/free-solid-svg-icons";
import InvitationDto from "../../models/Invitation";
import { searchMatch } from "../../data/stringHelpers";
import withPermission from "../../data/withPermission";
import { Permission } from "../../models/Permissions";
import Can from "../Can";
import useTranslation from "../../context/LanguageProvider";
import SearchPage from "../SearchPage";
import NoResults from "../NoResults";

const Employees: React.FC = () => {
  const [segment, setSegment] = useState("employees");
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [invitedEmployees, setInvitedEmployees] = useState<InvitationDto[]>([]);
  const [showSearchbar, setShowSearchbar] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [loadingEmployees, setLoadingEmployees] = useState(false);
  const [loadingInvites, setLoadingInvites] = useState(false);
  const { apiGet } = useApi();
  const { handleError } = useNotificationContext();
  const { t, tRole } = useTranslation();

  const getEmployees = useCallback(() => {
    setLoadingEmployees(true);
    apiGet<Employee[]>(`employee/getAll?searchTerm=${searchTerm}`)
      .then(setEmployees)
      .catch(handleError)
      .finally(() => setLoadingEmployees(false));
  }, [apiGet, handleError, searchTerm]);

  const getInvitations = useCallback(() => {
    setLoadingInvites(true);
    apiGet<InvitationDto[]>("invitation/getAll")
      .then(setInvitedEmployees)
      .catch(handleError)
      .finally(() => setLoadingInvites(false));
  }, [apiGet, handleError]);

  useIonViewWillEnter(() => {
    getEmployees();
    getInvitations();
  });

  useEffect(() => {
    getEmployees();
  }, [getEmployees]);

  return (
    <IonPage>
      <SearchPage
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        title={t("employees.title")}
        addNewPermission={Permission.InvitationsCreate}
        addNewRouterLink="/invite-employee"
        addNewTitle={t("employees.addEmployee")}
        hideMenuButton
        additionalToolbar={
          <IonToolbar className="header_toolbar2">
            <IonSegment
              onIonChange={e => {
                setSegment(e.detail.value as any);
                setSearchTerm("");
                setShowSearchbar(false);
              }}
              value={segment}
            >
              <IonSegmentButton value="employees">
                <IonLabel>{t("employees.joined")}</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value="invited">
                <IonLabel>{t("employees.invited")}</IonLabel>
              </IonSegmentButton>
            </IonSegment>
          </IonToolbar>
        }
      >
        <IonListHeader hidden={!showSearchbar || searchTerm === ""}>
          <IonLabel>
            {t("searchResultsFor")} <b>{searchTerm}</b>
          </IonLabel>
        </IonListHeader>
        {segment === "employees" &&
          (loadingEmployees ? (
            <IonSkeletonText animated />
          ) : (
            <IonList className="box lines">
              {employees.map((d, i) => (
                <IonItem key={i} button detail routerLink={"employee/" + d.id}>
                  <Icon slot="start" size="lg" icon={faIdBadge} />
                  <IonLabel>
                    {d.firstName} {d.lastName}
                    {!d.allowAccess && (
                      <IonNote color="warning"> [{t("inactive")}]</IonNote>
                    )}
                    <p>
                      {t("role")}: {tRole(d.roleId)}
                    </p>
                  </IonLabel>
                </IonItem>
              ))}
              {employees.length === 0 && (
                <NoResults title={t("employees.noEmployees")} />
              )}
            </IonList>
          ))}

        {segment === "invited" &&
          (loadingInvites ? (
            <IonSkeletonText animated />
          ) : (
            <IonList className="box lines">
              {invitedEmployees
                .filter(d => searchMatch(searchTerm, d.name))
                .map((d, i) => (
                  <IonItem
                    key={i}
                    button
                    detail
                    routerLink={"view-invitation/" + d.id}
                  >
                    <Icon
                      slot="start"
                      className="mediumColor"
                      size="lg"
                      icon={faIdBadge}
                    />

                    <IonLabel>
                      {d.name}{" "}
                      {d.acceptedByUserId > 0 ? (
                        <IonNote color="secondary">{t("accepted")}</IonNote>
                      ) : (
                        <IonNote color="primary">[{t("invited")}]</IonNote>
                      )}
                      <p>{d.email}</p>
                      <p>
                        {t("role")}: {tRole(d.roleId)}
                      </p>
                    </IonLabel>
                  </IonItem>
                ))}
            </IonList>
          ))}
        <Can permission={Permission.InvitationsCreate}>
          <div className="ion-text-center ion-margin-top">
            <IonButton routerLink="/invite-employee">
              <Icon icon={faPlus} />
              {t("employees.addEmployee")}
            </IonButton>
          </div>
        </Can>
      </SearchPage>
    </IonPage>
  );
};

export default withPermission(Employees, Permission.EmployeeRead);
