import {
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonList,
  IonRouterLink
} from "@ionic/react";
import React from "react";
import useTranslation from "../../context/LanguageProvider";
import DashboardDto from "../../models/DashboardDto";
import { CaseStatus, ProductionLogFormDto } from "../../models/Case";
import CaseStatusIcon from "../case/CaseStatusIcon";
import ScheduledDate from "../ScheduledDate";
import ProductionLogItem from "../productionLog/ProductionLogItem";
import useDate from "../../hooks/useDate";
import ValueLabel from "../ValueLabel";
import { useAuthContext } from "../../context/AuthProvider";
import CaseFlagIcon from "../case/CaseFlagIcon";

interface Props {
  dto: DashboardDto;
  setProductionLogInitialData: (v: ProductionLogFormDto) => void;
}

const DashboardCard: React.FC<Props> = ({
  dto,
  setProductionLogInitialData
}) => {
  const { user } = useAuthContext();
  const { t } = useTranslation();
  const { toDateTimeString } = useDate();

  return (
    <div className="box">
      <IonCardHeader className="dashboard_card_header">
        <IonRouterLink routerLink={"/case/" + dto.caseId}>
          <IonCardTitle>
            <>
              {dto.pan && (
                <span className="case-pan-number">
                  <b>{dto.pan} </b>
                </span>
              )}{" "}
              {user?.doctorId ? "" : " " + dto.doctorName}
              <CaseFlagIcon flag={dto.flag} />
              <br />
              {dto.patient ? (
                <i style={{ fontWeight: "slim" }}>{dto.patient}</i>
              ) : (
                <> </>
              )}
              <hr className="primary-line" />
            </>
          </IonCardTitle>
        </IonRouterLink>
      </IonCardHeader>

      <IonCardContent>
        <p>
          <CaseStatusIcon {...dto} id={dto.caseId} />
        </p>

        <p className="margin-top-6" />
        {dto.nextTryout && (
          <>
            <h3
              className="form-header"
              style={{ marginLeft: 0, marginTop: 16 }}
            >
              {t("cases.tryout")}: {dto.nextTryout.name}
            </h3>

            {dto.nextTryout.shipDate && (
              <div className="ion-hide-sm-up2 smaller-font">
                <ValueLabel title={t("dates.shipDate")}>
                  <ScheduledDate
                    date={dto.nextTryout.shipDate}
                    ignoreColor={[
                      CaseStatus.Finished,
                      CaseStatus.Cancelled
                    ].includes(dto.status)}
                    datePosition="after"
                    customCSSClass="smaller-font"
                  >
                    {" "}
                  </ScheduledDate>
                </ValueLabel>
              </div>
            )}
            {dto.nextTryout.appointmentDate && (
              <div className="ion-hide-sm-up2 smaller-font">
                <ValueLabel title={t("dates.appointmentDate")}>
                  <ScheduledDate
                    date={dto.nextTryout.appointmentDate}
                    ignoreColor={[
                      CaseStatus.Finished,
                      CaseStatus.Cancelled
                    ].includes(dto.status)}
                    datePosition="after"
                    customCSSClass="smaller-font"
                  >
                    {" "}
                  </ScheduledDate>
                </ValueLabel>
              </div>
            )}
          </>
        )}
        {dto.appointmentDate && (
          <p>
            <ScheduledDate
              date={dto.appointmentDate}
              ignoreColor={[CaseStatus.Finished, CaseStatus.Cancelled].includes(
                dto.status
              )}
              datePosition="after"
              customCSSClass="smaller-font"
            >
              {t("dates.finishDate") + ": "}
            </ScheduledDate>
          </p>
        )}
        {dto.shipDate && (
          <p>
            <ScheduledDate
              date={dto.shipDate}
              ignoreColor={[CaseStatus.Finished, CaseStatus.Cancelled].includes(
                dto.status
              )}
              datePosition="after"
              customCSSClass="smaller-font"
            >
              {t("dates.shipDate") + ": "}
            </ScheduledDate>
          </p>
        )}
        {dto.logs.length > 0 && (
          <>
            <div className="ion-margin-top">
              <h3
                className="form-header ion-no-margin"
                style={{ marginLeft: 0 }}
              >
                {t("workLog.title")}
              </h3>
            </div>
            <IonList className="ion-no-padding">
              {dto.logs.slice(0, 2).map((l, i) => (
                <ProductionLogItem
                  key={l.id}
                  caseStatus={CaseStatus.InProgress}
                  editProductionLog={l => setProductionLogInitialData({ ...l })}
                  deleteProductionLog={id => {}}
                  {...l}
                />
              ))}
            </IonList>
          </>
        )}

        <div className="ion-margin-top" hidden={!dto.lastMessage?.length}>
          <h3 className="form-header ion-no-margin" style={{ marginLeft: 0 }}>
            {t("chat.latestMessage")}
          </h3>
          <b>{dto.lastMessageAuthor}</b>: {dto.lastMessage}
          <br />
          {dto.lastMessageTime && (
            <p className="smaller-font">
              {toDateTimeString(new Date(dto.lastMessageTime).toString())}
            </p>
          )}
        </div>
      </IonCardContent>
    </div>
  );
};

export default DashboardCard;
