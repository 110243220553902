import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonButton,
  IonText
} from "@ionic/react";
import React, { useState } from "react";
import useApi from "../../data/Api";
import { useAuthContext } from "../../context/AuthProvider";
import { useHistory } from "react-router";
import { useNotificationContext } from "../../context/NotificationProvider";
import LabForm, { convertToFormData } from "./LabForm";
import useTranslation from "../../context/LanguageProvider";
import MiddleBox from "../MiddleBox";
import LabDto from "../../models/Lab";

const NewLab: React.FC = () => {
  const { apiPutMultiForm } = useApi();
  const { resetUser } = useAuthContext();
  const { handleError, showSuccessToast } = useNotificationContext();
  const history = useHistory();
  const { t } = useTranslation();

  const createLab = (lab: LabDto) =>
    apiPutMultiForm<number>("lab/CreateLab", convertToFormData(lab))
      .then(id => {
        resetUser().then(() => {
          showSuccessToast(t("newLab.createdMessage"));
          history.replace("");
          window.location.reload();
        });
      })
      .catch(handleError);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/settings" />
          </IonButtons>
          <IonTitle>{t("newLab.title")}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <MiddleBox className="box">
          <h3 className="ion-padding-left">{t("newLab.enterLabName")}</h3>
          <LabForm
            submitButtonText={t("newLab.title")}
            onSubmit={createLab}
            newLab
          />
          <div className="ion-text-center">
            <IonText className="greyColor">{t("or")}</IonText>
          </div>
          <IonButton routerLink="/invitation" expand="full" fill="clear">
            {t("joinLab.wantToJoinLab")}
          </IonButton>
        </MiddleBox>
      </IonContent>
    </IonPage>
  );
};

export default NewLab;
